import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { WelcomeViewModule } from '../1-welcome-view/welcome-view.module';

import { MainSelectionComponent } from './main-selection.component';
import { HeightResizerDirective } from './height-resizer.directive';
import { MessageChatDynamicDirective } from './message-chat-dynamic.directive';
import { OptionActionDirective } from './selection-option-action.directive';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatButtonModule,
  MatIconModule
];

const LIBRARIES = [
  WelcomeViewModule
];

const COMPONENTS = [
  MainSelectionComponent
];

const DIRECTIVES = [
  HeightResizerDirective,
  MessageChatDynamicDirective,
  OptionActionDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    MainSelectionComponent
  ]
})
export class MainSelectionModule { }
