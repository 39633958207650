import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, debounceTime, fromEvent, Subject, takeUntil, tap } from 'rxjs';

import { AppListenerService, WINDOW } from '@hiptraveler/common';
import { PLACEHOLDER_ATTR, setHighlightCursorElement } from './editable-selections.dom-helper';
import { Coordinates, EditableSelectionStatus } from '.';

@Injectable()
export class EditableSelectionsService implements OnDestroy {

  hostState: boolean = false;
  showSelection$$ = new BehaviorSubject<boolean>(false);
  subscription$ = new Subject<void>();
  showSelection$ = this.showSelection$$.asObservable();
  coordinates$$ = new BehaviorSubject<Coordinates | null>(null);
  coordinates$ = this.coordinates$$.asObservable();

  constructor(
    @Inject(WINDOW) private window: any,
    private appListener: AppListenerService,
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  private get yPosition(): number {
    return this.appListener.clientWidth$$.value > 1200 ? 200 : 100;
  }

  /**
   * Calculates the coordinates of the cursor element relative to the window and emits them as an object.
   *
   * @param {HTMLElement} cursorElement The element for which to calculate coordinates.
   * @private
   */
  private calculateAndEmitCoordinates(cursorElement: HTMLElement): void {
    setTimeout(() => {
      const clientRect = cursorElement.getBoundingClientRect;
      if (typeof clientRect !== 'function') return;
      const rect = cursorElement.getBoundingClientRect();
      const MARGIN_TOP_NAVBAR = 90;
      const HOST_SIZE = 44;
      this.coordinates$$.next({
        // top: (rect.top + window.scrollY - MARGIN_TOP_NAVBAR - 8 - 0.5) + 'px',
        top: (rect.top + window.scrollY - MARGIN_TOP_NAVBAR - 16 - 0.5) + 'px',
        left: (this.yPosition - HOST_SIZE - 16) + 'px',
        left1: (this.yPosition - HOST_SIZE - 16 + 60) + 'px',
        left2: (this.yPosition - HOST_SIZE - 16 + 110) + 'px'
      });
    });
  }

  /**
   * Toggles the host state and updates the placeholder attribute of a given editable element based on selection.
   *
   * @param {HTMLDivElement} editable - The editable element to modify.
   * @param {EditableSelectionStatus} [selection] - Optional selection status that affects behavior.
   */
  changeHostStateByElement(editable: HTMLDivElement, selection?: EditableSelectionStatus): void {
    const defaultPlaceholderValue = editable.getAttribute(PLACEHOLDER_ATTR)?.toString() || '';
    switch (selection) {
      case EditableSelectionStatus.ImageUpload: {
        this.hostState = false;
        editable.setAttribute(PLACEHOLDER_ATTR, defaultPlaceholderValue!);
        break;
      }
      case EditableSelectionStatus.VideoUrl: {
        this.hostState = false;
        editable.setAttribute(PLACEHOLDER_ATTR, '');
        break;
      }
      case EditableSelectionStatus.InsertText: {
        break;
      }
      default: {
        this.hostState = !this.hostState;
        editable.setAttribute(PLACEHOLDER_ATTR, this.hostState ? '' : defaultPlaceholderValue!);
      }
    }
  }

  /**
   * Sets up a listener to handle specific input events within a given editable element.
   *
   * @param {HTMLDivElement} editableElement - The editable element to listen for events on.
   * @param {TimerHandler} callback - The function to call when a relevant input event occurs.
   */
  editorNewLineAndDeleteListener(editableElement: HTMLDivElement, callback: TimerHandler): void {
    this.observeEvent(editableElement, 'click', (_: Event) => {
      const event = _ as InputEvent;
      const insertCond = event?.inputType === 'insertParagraph' || event?.inputType?.startsWith('delete');
      insertCond && setTimeout(callback);
    });
  }

  /**
  * Monitors user interactions within an editable element and manages the visibility and positioning of a host element
  * based on cursor position and content changes.
  *
  * @param {HTMLDivElement} editableElement - The editable element to observe.
  */
  hostStateObserver(editableElement: HTMLDivElement): void {

    this.observeEvent(editableElement, 'keyup', (event: KeyboardEvent) => {
      const selection = this.window.getSelection();
      const cond = selection && selection.anchorNode && (event.key === 'ArrowUp' || event.key === 'ArrowDown');
      if (cond) { // If keyup are arrow up or down
        const isWhiteSpace = (selection.anchorNode as HTMLElement).innerHTML?.includes('<br>');
        const element = (isWhiteSpace ? selection.anchorNode : selection.anchorNode.parentElement!) as Element;
        setHighlightCursorElement(element, selection); // Set highlight to parent of the element where cursor is active
        this.calculateAndEmitCoordinates(selection.anchorNode as HTMLElement); // Calculate and update the position of host beside the element where cursor is active
      }
      if (cond && selection.anchorNode.parentElement?.classList.toString().includes('highlight')) { // If keyup are arrow up or down and selection element has highlight class
        this.showSelection$$.next(!selection.anchorNode.parentElement.innerHTML); // Show host when selected element is empty, hide otherwise
      }
      if (cond && (selection?.anchorNode as HTMLElement).innerHTML === '<br>') { // If keyup are arrow up or down and element where caret is active is empty
        this.showSelection$$.next(true);
      }
      if (cond && (selection.anchorNode.firstChild?.nextSibling as HTMLElement)?.tagName === 'IFRAME') { // Hide host when cursor is on iframe
        this.hideHostElement();
      }
      if (cond && (selection.anchorNode.firstElementChild as HTMLElement)?.tagName === 'IMG') { // When selection element first child is an image
        setHighlightCursorElement(selection.anchorNode, selection); // Set highlight to parent of the element where cursor is active
        this.hideHostElement(); // Hide host where cursor is active
      }
    });

    this.observeEvent(editableElement, 'input', (_: Event) => {
      const event = _ as InputEvent;
      const selection = this.window.getSelection();
      const cond = selection && selection.anchorNode;
      if (cond) {
        setHighlightCursorElement(selection.anchorNode.parentElement) // Set highlight to parent of the element where cursor is active
        this.calculateAndEmitCoordinates(selection.anchorNode as HTMLElement); // Calculate and update the position of host beside the element where cursor is active
        this.showSelection$$.next((selection.anchorNode as HTMLElement)?.innerHTML?.includes('<br>')); // Show host when selected element is empty, hide otherwise
      }
      if (cond && selection.anchorNode.lastChild?.parentElement?.innerHTML.includes('<br>')) { // If the last child of the editor is empty
        setHighlightCursorElement(selection.anchorNode.lastChild?.parentElement as Element) // Ensures only the last child has the highlight class
      }
      if (event.inputType === 'insertParagraph' && editableElement.firstElementChild?.tagName === "DIV") {
        const paragraphElement = document.createElement("p");
        paragraphElement.innerHTML = editableElement.firstElementChild?.innerHTML!;
        editableElement.replaceChild(paragraphElement, editableElement.firstElementChild);
      }
      for (let child of Array.from(editableElement.children)) { // Makes sure all elements on insertParagraph are paragraph elements
        if (event.inputType === 'insertParagraph' && child.tagName === "DIV") {
          const paragraphElement = document.createElement("p");
          paragraphElement.innerHTML = child.innerHTML;
          editableElement.replaceChild(paragraphElement, child);
        }
      }
      event.inputType === 'insertParagraph' && setTimeout(() => { // Code to run on video url enter
        const currentElement = (selection?.anchorNode as HTMLElement);
        if (currentElement.previousElementSibling?.firstElementChild?.tagName !== 'IFRAME') return;
        this.calculateAndEmitCoordinates(currentElement); // Calculate and update the position of host beside the element where cursor is active
      }, 150);
    });

    this.observeEvent(editableElement, 'click', () => {
      const selection = this.window.getSelection();
      const selectionElement = selection?.anchorNode as HTMLElement | null | undefined;
      this.showSelection$$.next(!selectionElement?.textContent); // Hide host when selected element is not empty
      if (selectionElement) {
        setHighlightCursorElement(selectionElement); // Set highlight to parent of the element where cursor is active
        this.calculateAndEmitCoordinates(selectionElement); // Calculate and update the position of host beside the element where cursor is active
      }
      if (selectionElement?.firstElementChild?.tagName === 'IMG') { // Hide host when selected element is img tag
        this.hideHostElement();
      }
    });
    
    this.observeEvent(editableElement, 'focus', () => {
      if (!this.hostState) return;
      const selection = this.window.getSelection();
      const selectionElement = selection?.anchorNode as HTMLElement | null | undefined;
      if (selectionElement?.innerHTML === '<br>') { // Checks if the element selected is empty
        setHighlightCursorElement(selectionElement) // Set highlight to parent of the element where cursor is active
        this.calculateAndEmitCoordinates(selectionElement); // Calculate and update the position of host beside the element where cursor is active
      } else {
        this.hideHostElement();
      }
    })

  }

  hideHostElement(): void {
    this.showSelection$$.next(false);
    this.hostState = false;
  }

  private observeEvent<T>(editableElement: HTMLDivElement, event: string, callback: (event: T | any) => void): void {
    fromEvent(editableElement, event).pipe(
      debounceTime(500),
      tap(callback),
      takeUntil(this.subscription$)
    ).subscribe();
  }

}
