import { Injectable } from '@angular/core';
import { BehaviorSubject, delay, distinctUntilChanged, map, Subject } from 'rxjs';

import { AppListenerService, getWindowRef } from '@hiptraveler/common';

const MOBILE_WIDTH: number = 700;

@Injectable({
  providedIn: 'root'
})
export class ExperienceFinderStateService {

  displayState$$ = new Subject<boolean>();
  displayState$ = this.displayState$$.asObservable();

  overlayState$$ = new BehaviorSubject<boolean>(false);
  overlayState$ = this.overlayState$$.asObservable().pipe(delay(0));

  transparentBackdrop$$ = new BehaviorSubject<boolean>(false);
  transparentBackdrop$ = this.transparentBackdrop$$.asObservable().pipe(delay(0));
  
  processing$$ = new BehaviorSubject<boolean>(false);
  
  overlayPositionState$$ = new BehaviorSubject<boolean>(true);
  overlayPositionState$ = this.overlayPositionState$$.asObservable().pipe(
    distinctUntilChanged(),
    delay(0)
  );

  isMobile$ = this.appListener.clientWidth$.pipe( map(e => e <= MOBILE_WIDTH) );
  isDesktop$ = this.isMobile$.pipe( map(e => !e) );

  get isMobile(): boolean {
    return getWindowRef().innerWidth <= MOBILE_WIDTH;
  }

  constructor(
    private appListener: AppListenerService
  ) { }

}
