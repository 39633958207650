import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { delay } from 'rxjs';

import { AuthApiService } from '@hiptraveler/data-access/api';
import { AuthAction } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { SearchAction, setAdventuresQuery, setCommunitiesQuery, setFoodsQuery, setHotelsQuery, setItinerariesQuery } from '@hiptraveler/data-access/search';
import { SnackbarService } from '@hiptraveler/snackbar';
import { getWindowRef, ofActionDispatchedSignOutNoAuthState, signoutAccessToken } from '@hiptraveler/common';
import { ItineraryAction } from '@hiptraveler/data-access/itinerary';

@Injectable()
export class DispatchListenerService {

  constructor(
    private store: Store,
    private actions$: Actions,
    private authApi: AuthApiService,
    private snackbar: SnackbarService
  ) { }

  observe(): void {

    this.actions$.pipe(
      ofActionDispatched(AuthAction.SignOut)
    ).subscribe(() => {
      this.authApi.invalidateApiCookie().subscribe();
    });

    this.actions$.pipe(
      ofActionDispatched(AuthAction.SignOut),
      delay(1000 * 9)
    ).subscribe(() => {
      this.store.selectSnapshot(UserState.authenticated)
      || (getWindowRef()[signoutAccessToken] = null);
    });

    this.actions$.pipe(
      ofActionDispatched(AuthAction.SignOutNoAuth),
    ).subscribe(() => {
      getWindowRef()[ofActionDispatchedSignOutNoAuthState] = '1';
      this.snackbar.open({ message: 'Session expired. Please sign in again.', duration: 5000 });
      this.store.dispatch(new ItineraryAction.PartialResetItineraryState([ 'basicInfo', 'actDateMap' ]))
    });

    this.actions$.pipe(
      ofActionDispatched(
        SearchAction.GetItinerariesContent,
        SearchAction.GetItinerariesByQuery
      )
    ).subscribe((value: any) => setItinerariesQuery(value.query));

    this.actions$.pipe(
      ofActionDispatched(
        SearchAction.GetAdventuresContent,
        SearchAction.GetAdventuresByQuery,
        SearchAction.GetAdventureTermByQuery
      )
    ).subscribe((value: any) => setAdventuresQuery(value.query));

    this.actions$.pipe(
      ofActionDispatched(
        SearchAction.GetHotelsContent,
        SearchAction.GetHotelsByQuery,
        SearchAction.GetHotelsTermByQuery
      )
    ).subscribe((value: any) => setHotelsQuery(value.query));

    this.actions$.pipe(
      ofActionDispatched(
        SearchAction.GetFoodsContent,
        SearchAction.GetFoodsByQuery,
        SearchAction.GetFoodsTermByQuery
      )
    ).subscribe((value: any) => setFoodsQuery(value.query));

    this.actions$.pipe(
      ofActionDispatched(
        SearchAction.GetCommunitiesByQuery,
      )
    ).subscribe((value: any) => setCommunitiesQuery(value.query));
  }

}
