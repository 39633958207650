import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BasicInfoData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { ViewService } from '@hiptraveler/features/itinerary';
import { getWindowRef, mediumEditorNamespaceKey, NavbarControlStateService, ScrollListenerService } from '@hiptraveler/common';

@Injectable()
export class StoryContentService extends ViewService implements OnDestroy {

  @Select(ItineraryState.basicInfo) basicInfo$: Observable<Partial<BasicInfoData> | null>;

  constructor(
    a: NavbarControlStateService, b: ScrollListenerService
  ) {
    super(a, b);
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  initializeMediumEditor(editable: ElementRef<HTMLDivElement>): void {
    const me = getWindowRef()[mediumEditorNamespaceKey];
    if (!me || !editable?.nativeElement) return undefined;
    new me(editable.nativeElement, {
      disableEditing: true,
      toolbar: false
    });

    this.basicInfo$.subscribe(basicInfo => {
      const storyContent = basicInfo?.content;

      if (editable.nativeElement.innerHTML) return;
      editable.nativeElement.innerHTML = storyContent || '';

      storyContent && editable.nativeElement.removeAttribute('data-placeholder');
    });
  }

}
