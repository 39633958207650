import { FollowUserDto, User, UserBlogData, UserItineraryData } from '@hiptraveler/data-access/api';

export class SetBlogsAndItineraryData {
  static readonly type = '[User State] SetBlogsAndItineraryData';
  constructor(public data: User) { }
}

export class FollowProfileByUserId {
  static readonly type = '[User State] FollowUserById';
  constructor(public data: FollowUserDto) { }
}

export class CheckVisitorSession {
  static readonly type = '[User State] CheckVisitorSession';
  constructor() { }
}

export class UpdateItineraryData {
  static readonly type = '[User State] UpdateItineraryData';
  constructor(public data: UserItineraryData[]) { }
}

export class UpdateBlogData {
  static readonly type = '[User State] UpdateBlogData';
  constructor(public data: UserBlogData[]) { }
}

export class UpdateProfileImage {
  static readonly type = '[User State] UpdateProfileImage';
  constructor(public data: string) { }
}

export class ResetUserState {
  static readonly type = '[User State] ResetUserState';
  constructor() { }
}
