import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { filter } from 'rxjs';

import { BannerService } from './banner.service';
import { FilestackService } from '@hiptraveler/core/filestack';
import { ToolbarOption, authenticatedProfilePlaceholder, profilePlaceholder } from '@hiptraveler/common';
import { opacityFade, slideInFromTop } from '@hiptraveler/animations';
import { ProfileStateService } from '../common';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss', './banner-responsiveness.scss'],
  host: { class: 'page-profile-banner' },
  viewProviders: [ BannerService, FilestackService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade(), slideInFromTop() ]
})
export class BannerComponent implements OnInit {

  selections: ToolbarOption[] = this.service.selections;
  profilePlaceholder: string = profilePlaceholder;
  authenticatedProfilePlaceholder: string = authenticatedProfilePlaceholder;
  navbarAppend: boolean = false;
  uploadImageProcessing: boolean = false;

  constructor(
    public service: BannerService,
    public stateService: ProfileStateService
  ) { }

  ngOnInit(): void {
    this.service.routeChangesObserver();
    this.service.navbarAndToolbarObserver();

    this.service.navbarAppend$.subscribe((state: boolean) => {
      this.navbarAppend = state;
    });
    this.stateService.uploadImageProcessing$$.subscribe((state: boolean) => {
      this.uploadImageProcessing = state;
    });
    this.stateService.travelAgent$.pipe(filter(Boolean)).subscribe(() => {
      if (!this.service.authenticated) return;
      const selection = this.service.parseSelection({ name: 'my-uploads-option', url: 'profile/_PROFILE/uploads' });
      this.selections.push(selection);
    });
  }

}
