import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map } from 'rxjs';

import { CarouselFilterType, EFAStateServiceService, FormCarouselData, ScreenView } from '../../shared';
import { getScreen } from './get-screen';

const carousels: ScreenView[] = [
  ScreenView.travelCarousel,
  ScreenView.experienceCarousel,
  ScreenView.activitiesCarousel,
  ScreenView.locationCarousel
];

@Pipe({
  name: 'includes'
})
export class IncludesPipe implements PipeTransform {

  constructor(
    private stateService: EFAStateServiceService
  ) { }

  transform(value: string, selectedFilter: CarouselFilterType): Observable<boolean> {
    return this.stateService.carouselData$.pipe(
      map((data: FormCarouselData) => {
          
        const screen = getScreen(selectedFilter);
        if (screen && carousels.includes(screen)) {
          return (data as any)?.[screen]?.includes(value)
        }

        return false;
      })
    );
  }

}
