import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, firstValueFrom } from 'rxjs';

import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { ItineraryStateService } from '@hiptraveler/features/itinerary';
import { SnackbarService } from '@hiptraveler/snackbar';
import { RequestCancellationService, currentLang } from '@hiptraveler/common';

@Injectable()
export class BlogDataAccessService {

  subscription$ = new Subject<void>();
  resetStateFunc: () => void;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private itineraryState: ItineraryStateService,
    private requestCancellation: RequestCancellationService,
    private snackbar: SnackbarService
  ) { }

  /**
   * Fetches blog data based on the current route's pageTitle parameter.
   */
  async getBlogData(resetState: () => void): Promise<void> {

    this.resetStateFunc = resetState;
    const data = this.store.selectSnapshot(ItineraryState.basicInfo);
    const actDate = this.store.selectSnapshot(ItineraryState.actDateArr) || [];

    if (
      !!data && data.hasItinerary && !actDate.length
    ) return this.loadActivityDateMap();

    if (!!data || this.itineraryState.dispatchPending$$.value) return;

    this.requestCancellation.cancelAllRequests();
    this.itineraryState.dispatchPending$$.next(true);
    setTimeout(() => this.requestData());
  }

  /**
   * Private method to handle the actual data fetching process.
   */
  async requestData(pageTitleId?: string): Promise<void> {
    try {
      const pageTitle = pageTitleId || this.route.snapshot.params['blog'];

      const promises = [
        new ItineraryAction.PartialResetItineraryState([ 'basicInfo', 'actDateMap' ]),
        new ItineraryAction.GetBlogByPageTitle(pageTitle)
      ].map(e => firstValueFrom(this.store.dispatch(e)));

      await Promise.all(promises);
      this.store.selectSnapshot(ItineraryState.basicInfo)?.hasItinerary
        && this.loadActivityDateMap();
    } catch (response) {
      this.resetState();
    } finally {
      this.snackbar.dismiss();
      this.itineraryState.dispatchPending$$.next(false);
    }
  }

  private async loadActivityDateMap(): Promise<void> {

    const basicInfo = this.store.selectSnapshot(ItineraryState.basicInfo)!;
    const itineraryCount = Math.ceil((basicInfo.numDays || 1) / 7);

    try {
      await firstValueFrom(this.store.dispatch(new ItineraryAction.GetItineraryTripByIdAndCount(basicInfo.id!, itineraryCount, 'blog')));
    } catch (e) {
      this.resetState();
    }
  }

  private async resetState(value?: { errorMessage: string }): Promise<void> {
    setTimeout(() => this.resetStateFunc(), 1000);
    await this.router.navigate([ '/', currentLang(), 'search' ]);
    this.snackbar.open({ message: value?.errorMessage || 'Something went wrong. Please try again.', duration: 5000 });
  }

}
