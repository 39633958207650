import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngxs/store';
import { filter, firstValueFrom, switchMap, take, takeUntil, tap } from 'rxjs';

import { BasicInfoData, UploadItineraryDto } from '@hiptraveler/data-access/api';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { BlogDataAccessService, preventRemoveKey, ViewNavbarAction } from '@hiptraveler/features/itinerary';
import { AppListenerService, NavbarControlStateService, currentLang, getWindowRef } from '@hiptraveler/common';
import { SnackbarService } from '@hiptraveler/snackbar';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';

@Directive({
  selector: '[navbarActionListener]'
})
export class NavbarActionListenerDirective extends ViewNavbarAction implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private location: Location,
    private dataAccess: BlogDataAccessService,
    private snackbar: SnackbarService,
    store: Store,
    appListener: AppListenerService,
    navbarControl: NavbarControlStateService,
    authDialog: AuthDialogActionService
  ) { 
    super(store, appListener, navbarControl, authDialog);
  }

  ngOnInit(): void {
    this.navbarActionListener();
    this.navbarActionStateObserver();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
    this.resetAuthHandle();
  }

  private async publishTravelStoryBlog(basicInfo: BasicInfoData): Promise<void> {
    try {
      await firstValueFrom(this.store.dispatch(new ItineraryAction.UploadItineraryBlog<UploadItineraryDto>({
        action: 'publish-review',
        id: basicInfo.id,
        lang: currentLang()
      }, 'blog')));
      this.snackbar.open({ message: 'Thank you for submitting your travel story! It has been sent for review and is now under consideration for publishing.', duration: 5000 });
      this.setUnderReviewBlogNavbarActions();
    } catch (response) {
      this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
    } finally {
      this.navbarControl.navbarToolbarActionComplete$$.next();
    }
  }

  async saveItineraryTripBlog(): Promise<void> {

    if (this.authenticationProcess()) return;

    try {
      const id = this.store.selectSnapshot(ItineraryState.basicInfo)!.id!;
      await firstValueFrom (this.store.dispatch(new ItineraryAction.SaveItineraryTripBlog({ id })));
      const pageTitle = this.store.selectSnapshot(ItineraryState.basicInfo)!.pageTitle;
      await this.dataAccess.requestData(pageTitle);
    } finally {
      this.navbarControl.navbarToolbarActionComplete$$.next();
    }
  }

  private navbarActionListener(): void {;

    this.observeCreatePDF();

    this.appListener.globalSignalListener('saveThisTrip').pipe(
      tap(this.saveItineraryTripBlog.bind(this)),
      takeUntil(this.subscription$)
    ).subscribe();

    this.appListener.globalSignalListener('travelStoryEdit').pipe(
      tap(() => {
        getWindowRef()[preventRemoveKey] = '1';
        const pageTitle = this.location.path().replace(`/${currentLang()}/travel-story/`, '');
        this.router.navigate([ currentLang(), 'compose', 'travel-story', pageTitle ]);
      }),
      takeUntil(this.subscription$)
    ).subscribe();

    this.appListener.globalSignalListener('travelStoryPublish').pipe(
      switchMap(() => this.basicInfo$.pipe( filter(Boolean), take(1) )),
      filter(Boolean),
      tap(this.publishTravelStoryBlog.bind(this)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  private setUnderReviewBlogNavbarActions(): void {
    let navbarActions = this.navbarControl.activeNavbarActionButtons$$.value || [];
    navbarActions = navbarActions.filter(e => e?.action !== 'travelStoryPublish')
    this.navbarControl.setActiveNavbarButtons(navbarActions)
  }

}
