import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { firstValueFrom, map, Observable, of, tap } from 'rxjs';

import { AuthState } from '@hiptraveler/data-access/auth';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { SearchPageControlStateService, clientVID, currentLang } from '@hiptraveler/common';

@Injectable()
export class AuthenticatedGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private store: Store,
    private searchPageControl: SearchPageControlStateService
  ) { }

  canActivate(_: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot): Observable<boolean> | boolean {

    // return of(true); // For testing only

    if (!this.router.navigated && this.composePage(snapshot)) return true;

    if (this.addItineraryStateBySnapshot(snapshot)) return true;
    
    return this.store.select(AuthState.authenticated).pipe(
      map((authenticated: boolean) => {
        const visitorOwner = this.store.selectSnapshot(ItineraryState.basicInfo)?.author?.authorProfId === clientVID();
        return authenticated || visitorOwner;
      }),
      tap(this.analyzeRoute.bind(this))
    );
  }
  
  canActivateChild(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.canActivate(_, __);
  }

  private composePage(snapshot: RouterStateSnapshot): boolean {
    return snapshot.url.startsWith(`/${currentLang()}/compose/itinerary/`)
      || snapshot.url.startsWith(`/${currentLang()}/compose/travel-story/`);
  }

  private addItineraryStateBySnapshot(snapshot: RouterStateSnapshot): boolean {
    return !!this.searchPageControl.activityDate$$.value && this.composePage(snapshot);
  }

  private async analyzeRoute(authenticated: boolean): Promise<void> {

    if (authenticated) return;

    await firstValueFrom(this.store.dispatch(new ItineraryAction.PartialResetItineraryState([ 'basicInfo', 'actDateMap', 'itineraryReservations', 'travelAgents' ])));
    this.router.navigate([ currentLang() ]);
  }
  
}
