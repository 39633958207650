import { ScreenView } from "./model";

export const screens = [
  ScreenView.welcome, ScreenView.mainSelection, ScreenView.experienceCarousel,
  ScreenView.travelCarousel, ScreenView.activitiesCarousel, ScreenView.locationCarousel,
  ScreenView.calendar, ScreenView.clientInfo, ScreenView.summary
] as ScreenView[];

export function getActiveScreen(scrollTop: number, offset: number = 0): ScreenView {
  const screenState = screenViewState(scrollTop, offset)
  return Object.keys(screenState).find((screen: any) => screenState[screen as ScreenView] === true) as ScreenView;
}

export function screenViewState(scrollTop: number, offset: number = 0): Record<ScreenView, boolean> {
  return {
    screen1: scrollTop >= 0 && scrollTop < 400,
    screen2: scrollTop >= 400 - offset && scrollTop < 800 - offset,
    screen3: scrollTop >= 800 - offset && scrollTop < 1200 - offset,
    screen4: scrollTop >= 1200 - offset && scrollTop < 1600 - offset,
    screen5: scrollTop >= 1600 - offset && scrollTop < 2000 - offset,
    screen6: scrollTop >= 2000 - offset && scrollTop < 2400 - offset,
    screen7: scrollTop >= 2400 - offset && scrollTop < 2800 - offset,
    screen8: scrollTop >= 2800 - offset && scrollTop < 3200 - offset,
    screen9: scrollTop >= 3200 - offset && scrollTop < 3600 - offset,
    screen10: scrollTop >= 3600 - offset && scrollTop < 4000 - offset,
    screen11: scrollTop >= 4000 - offset && scrollTop < 4400 - offset,
    screen12: scrollTop >= 4400 - offset && scrollTop < 4800 - offset
  };
}
