<div class="host-profile-selection" [class.user]="userState$ | async">
  <ng-container *ngIf="userAuthenticated$ | async;else notAuthenticated">
    <ng-container *ngIf="userState$ | async as user">
  
      <ng-container *ngIf="user.picUrl;else noPicUrlFallback">
        <img [lazyLoad]="user.picUrl" [defaultImage]="authenticatedProfilePlaceholder" [errorImage]="authenticatedProfilePlaceholder" draggable="false" [alt]="user.uTitle" [matMenuTriggerFor]="profileSelectionMenu">
      </ng-container>
      <ng-template #noPicUrlFallback>
        <user-profile-svg [matMenuTriggerFor]="profileSelectionMenu"></user-profile-svg>
      </ng-template>
    
      <mat-menu #profileSelectionMenu="matMenu" xPosition="before" [class]="'host-profile-selection--menu ' + className">

        <button mat-menu-item (click)="navigateToProfile()">
          <mat-icon svgIcon="navbar.profile"></mat-icon>
          <span translate="navbar.profile-selection-profile"></span>
        </button>

        <mat-divider *ngIf="divider === ''"></mat-divider>

        <button mat-menu-item (click)="signOut()">
          <mat-icon svgIcon="navbar.sign-out"></mat-icon>
          <span translate="navbar.profile-selection-sign-out"></span>
        </button>

        <ng-container *ngIf="!(hiptravelerBrand$ | async)">

          <mat-divider *ngIf="divider === ''"></mat-divider>

          <button mat-menu-item (click)="exploreHiptraveler()">
            <mat-icon svgIcon="navbar.explore-hiptraveler"></mat-icon>
            <span translate="navbar.profile-selection-explore"></span>
          </button>

        </ng-container>

      </mat-menu>
    </ng-container>
  </ng-container>
  <ng-template #notAuthenticated>

    <ng-container *ngIf="authenticated$ | async;else notLoggedIn">
      <mat-progress-spinner mode="indeterminate" [diameter]="35" [strokeWidth]="5"></mat-progress-spinner>
    </ng-container>
    <ng-template #notLoggedIn>
      <user-profile-svg (click)="openAuthDialog()"></user-profile-svg>
    </ng-template>

  </ng-template>
</div>

