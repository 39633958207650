import { ElementRef, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, firstValueFrom, map } from 'rxjs';

import { AuthDialogComponent } from '../auth-dialog.component';
import { AuthAction, AuthState } from '@hiptraveler/data-access/auth';
import { BrandState } from '@hiptraveler/data-access/brand';
import { SnackbarService } from '@hiptraveler/snackbar';
import { BAHAMAS_BRAND_NAME, PlaceResult, RegexPattern, currentLang, initializeGoogleMapsPlacesAutocomplete, isWidget, subdomain } from '@hiptraveler/common';
import { AUTH_REGISTER_DIALOG_ID } from './auth-register-dialog-action.service';
import { BrandCampaign } from '@hiptraveler/data-access/api';

@Injectable()
export class AuthRegisterDialogService {
  
  form: FormGroup;
  subscription$ = new Subject<void>();
  pending$$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(FormBuilder) private fb: FormBuilder,
    private dialog: MatDialog,
    public store: Store,
    private snackbar: SnackbarService
  ) { }

  get hiptraveler(): boolean {
    return subdomain() === 'hiptraveler';
  }

  get bahamas(): boolean {
    return subdomain() === BAHAMAS_BRAND_NAME;
  }

  get brandTitle$(): Observable<string> {
    return this.store.select(BrandState.brandCampaign).pipe(
      map(e => e?.cTitle ? `${e?.cTitle?.[0].toUpperCase()}${e?.cTitle?.slice(1)}` : 'HipTraveler')
    );
  }

  get hasTravelAgents$(): Observable<boolean> {
    return this.store.select(BrandState.brandCampaign).pipe(
      map((brandCampaign: Partial<BrandCampaign> | null) => !!brandCampaign?.hasTravelAgents)
    );
  }

  get hasNewsLetter$(): Observable<boolean> {
    return this.store.select(BrandState.brandCampaign).pipe(
      map((brandCampaign: Partial<BrandCampaign> | null) => !!brandCampaign?.campaignCustom?.hasNewsLetter && this.bahamas)
    );
  }

  initializeForm(): void {

    const validators = {
      firstName: [ Validators.required, Validators.pattern(RegexPattern.alphaSpecialChar), Validators.minLength(2), Validators.maxLength(20) ],
      lastName: [ Validators.required, Validators.pattern(RegexPattern.alphaSpecialChar), Validators.minLength(2), Validators.maxLength(50) ],
      email: [ Validators.required, Validators.pattern(RegexPattern.email) ],
      password: [ Validators.required, Validators.pattern(RegexPattern.password) ],
      userLoc: [ Validators.required ]
    };

    this.form = this.fb.group({
      firstName: [ '', validators.firstName ],
      lastName: [ '', validators.lastName ],
      email: [ '', validators.email ],
      password: [ '', validators.password ],
      userLoc: [ '', validators.userLoc ],
      location: [ '', validators.userLoc ],
      userType: [ 'htUser' ],
      lang: [ currentLang() ],
      acceptNewsLetter: [ false ],
      travelTrade: [ false ],
      registeredUsingMedia: [ false ]
    });

    this.form.get('travelTrade')?.valueChanges.subscribe((checked: boolean) => {
      this.form.patchValue({ userType: checked ? 'htAgent' : 'htUser' });
    });
  }

  initializeAutocomplete(element: ElementRef): void {
    isPlatformBrowser(this.platformId) && initializeGoogleMapsPlacesAutocomplete(element, { default: true }).subscribe((response: PlaceResult) => {
      this.form.patchValue({ userLoc: response?.formatted_address, location: response?.formatted_address });
    });
  }

  async signUpWithEmailAndPassword(callback?: (success: boolean) => void): Promise<void> {

    if (this.form.invalid) {
      this.snackbar.open({ message: 'Some fields have errors or are missing. Please check your details and try again.', duration: 5000 });
      return callback?.(true);
    }

    if (!this.form.value.userLoc) {
      this.form.patchValue({ location: '' });
      this.snackbar.open({ message: 'Hometown is required.', duration: 5000 });
      return callback?.(true);
    }

    if (this.pending$$.value || this.form.invalid) return;
    const form = this.form.value;
    delete form['travelTrade'];
    delete form['location'];
    this.pending$$.next(true);
    try {
      await firstValueFrom(this.store.dispatch(new AuthAction.Register(form)));
      isWidget() ? callback?.(false) : this.dialog.getDialogById(AUTH_REGISTER_DIALOG_ID)?.close();
    } catch (response: any) {
      callback?.(true)
      this.snackbar.open({ 
        message: response?.message === 'user_exists' 
          ? 'The email address is already in use. Please try again.'
          : 'Something went wrong. Please try again.',
        duration: 5000
      });
    } finally {
      this.form.reset();
      this.pending$$.next(false);
    }
  }
  
  openLoginDialog(): MatDialogRef<AuthDialogComponent, any> | undefined {
    if (this.store.selectSnapshot(AuthState.authenticated)) return;
    return this.dialog.open(AuthDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      panelClass: 'auth-login-dialog-overlay-wrapper',
      width: '460px',
      maxWidth: 'auto',
      minWidth: 0,
      minHeight: 0,
      maxHeight: '100vh'
    });
  }

}
