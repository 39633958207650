import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter, firstValueFrom, map, take, tap } from 'rxjs';

import { BasicInfoData } from '@hiptraveler/data-access/api';
import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { AppListenerService, currentLang, getWindowRef, NavbarControlStateService, requestEndpointParamValue, SearchLocationService, SearchPageControlStateService } from '@hiptraveler/common';

@Injectable()
export class NavigationChangeObserverService {

  signedOut: boolean = false;
  
  constructor(
    private router: Router,
    private store: Store,
    private appListener: AppListenerService,
    private navbarControl: NavbarControlStateService,
    private searchLocation: SearchLocationService,
    private searchPageControl: SearchPageControlStateService
  ) { }

  private get searchPage(): boolean {
    return this.searchLocation.rootSearchRoute
      || getWindowRef()?.location?.pathname?.includes(`/${currentLang()}/search/`)
  }

  observe(): void {

    this.signedOut = false;

    this.store.select(UserState.authenticated).subscribe((state: boolean) => {
      const pathname = getWindowRef()?.location?.pathname;
      if (!pathname?.match(new RegExp(`/${currentLang()}/(itinerary|travel-story)/`))) return;

      this.signedOut = !state;
    });

    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map(e => e.url)
    ).subscribe(async (activeUrl: string) => {

      const previousUrl = this.appListener.previousUrl$$.value;
      const composePagePrevUrl = previousUrl.includes(`/${currentLang()}/compose/`);
      const itineraryViewPagePrevUrl = previousUrl.includes(`/${currentLang()}/itinerary/`);
      const basicInfo = this.store.selectSnapshot(ItineraryState.basicInfo);

      const activity: any = this.store.selectSnapshot(ItineraryState.actDateMap)?.['1'];

      if (activeUrl.includes(`/${currentLang()}/compose/`)) {
        this.searchPageControl.activityDate$$.next(null);
        return;
      }

      if (new RegExp(`/${currentLang()}/(itinerary|travel-story)/`).test(activeUrl)) {
        const pageTitle = activeUrl.split('/')[3];
        this.store.select(ItineraryState.basicInfo).pipe(
          filter(Boolean),
          filter(e => e?.pageTitle === pageTitle),
          tap((basicInfo: Partial<BasicInfoData>) => {
            const itineraries = this.store.selectSnapshot(UserState.itineraries);
            const itinerary = itineraries?.find(e => e.pageTitle === basicInfo?.pageTitle);
            itinerary && this.searchPageControl.activityDate$$.next(null);
          }),
          take(1)
        ).subscribe();
        return;
      }

      if (this.searchPage && activity?.pageTitle && !basicInfo) {
        try {
          await firstValueFrom(this.store.dispatch(new ItineraryAction.GetBasicInfoByPageTitle(activity.pageTitle)));
          const param = getWindowRef()?.[requestEndpointParamValue] === 'blog' ? 'travel-story' : 'itinerary';
          this.navbarControl.setActiveNavbarButtons([{
            name: 'navbar.action-text-open-trip',
            url: `${currentLang()}/compose/${param}/${activity?.pageTitle}`,
            closeButton: true
          }]);
        } finally { }
      }

      if ((composePagePrevUrl || itineraryViewPagePrevUrl) && basicInfo?.pageTitle && activity && !this.signedOut) {
        const subject = this.searchPageControl.activityDate$$;
        activity['pageTitle'] = basicInfo.pageTitle;
        subject.value || subject.next(activity);
        const param = basicInfo.type === 'blog' ? 'travel-story' : 'itinerary';
        this.navbarControl.setActiveNavbarButtons([{
          name: 'navbar.action-text-open-trip',
          url: `${currentLang()}/compose/${param}/${basicInfo.pageTitle}`,
          closeButton: true
        }]);
      }
    });
  }

}
