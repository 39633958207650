import { Directive, NgModule, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Actions, Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { BasicInfoData, UserBlogData } from '@hiptraveler/data-access/api';
import { UserAction, UserState } from '@hiptraveler/data-access/user';
import { encryptString, ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { ComponentStateService } from '@hiptraveler/features/itinerary';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { AppListenerService, NavbarControlStateService, SearchPageControlStateService, clientVID, currentLang, getLanguageName, updateOrderItinerariesKey } from '@hiptraveler/common';
import { toLocationObject, ItineraryActionDirective } from '.';
import { SnackbarService } from '@hiptraveler/snackbar';

/**
 * @directive BlogNavbarActionDirective
 * @description Directive that listens for navbar button signals and performs actions related to blog editing.
 */
@Directive({
  selector: '[navbarAction]'
})
export class BlogNavbarActionDirective extends ItineraryActionDirective implements OnInit {

  constructor(
    private location: Location,
    private snackbar: SnackbarService,
    a: Router, b: MatDialog, c: Store, d: Actions, e: AppListenerService,
    f: AuthDialogActionService, g: NavbarControlStateService,
    h: SearchPageControlStateService, i: ComponentStateService
  ) {
    super(a, b, c, d, e, f, g, h, i);
  }
  

  ngOnInit(): void {
    this.authenticationObserver(this.composePreview.bind(this));
    this.navbarButtonListener('exitFocusMode', this.exitFocusMode.bind(this));
    this.navbarButtonListener('composePreview', this.composePreview.bind(this));
    this.navbarButtonListener('composeSave', this.composeSave.bind(this));
  }

  ngOnDestroy(): void {
    this.resetAuthHandle();
    this.componentState.reset();
    this.subscription$.next();
  }

  /**
   * Generate a preview of the blog and navigate to the preview page.
   */
  composePreview(): void {
    if (!this.store.selectSnapshot(ItineraryState.basicInfo)?.pageTitle) {
      this.snackbar.open({ message: 'Blog preview unavailable. Save your changes first.' });
      setTimeout(() => this.navbarControl.navbarToolbarActionComplete$$.next(), 350);
      return;
    }
    const pageTitle = this.location.path().replace(`/${currentLang()}/compose/travel-story/`, '');
    this.router.navigate([ currentLang(), 'travel-story', pageTitle ]);
    setTimeout(() => this.navbarControl.navbarToolbarActionComplete$$.next(), 350);
  }

  /**
   * Save the blog content to the server.
   */
  async composeSave(): Promise<void> {

    if (this.authTrigger('blogComposePage')) return;

    this.observeDispatchSuccess();

    try {

      if (!this.navbarControl.navbarTitleState$$.value) {
        this.snackbar.open({ message: 'Unable to save. Please provide a story title in the required field.', duration: 5000 });
        return;
      }

      const updateOrderItineraries = sessionStorage.getItem(updateOrderItinerariesKey);
      const promises = [ this.basicInfoUpdate() ];
      updateOrderItineraries && promises.push(this.updateOrderUpdate(updateOrderItineraries));
      this.searchPageControl.featureCardProcessing$$.next(true);
      await Promise.all(promises);
    } finally {
      this.searchPageControl.featureCardProcessing$$.next(false);
      this.navbarControl.navbarToolbarActionComplete$$.next();
    }
  }

  /**
   * Update basic information of the blog in the store and dispatch a save action.
   */
  private async basicInfoUpdate(): Promise<void> {

    const basicInfo = this.store.selectSnapshot(ItineraryState.basicInfo);
    if (!basicInfo?.title) return this.addBasicInfo();
    
    let newBasicInfo = { ...basicInfo } as any;
    const value = this.componentState.subscription$$.value;
    newBasicInfo.title = this.navbarControl.navbarTitleState$$.value || basicInfo.title;
    // newBasicInfo.content = encryptString(value?.storyContent || basicInfo?.content || '');
    const element = document.getElementsByClassName('hiptraveler-content-editable')[0] as HTMLDivElement;
    newBasicInfo.content = element?.innerHTML || basicInfo?.content;
    newBasicInfo.locations = value?.locationList?.map(e => toLocationObject(e)).filter(Boolean);
    newBasicInfo.coverImage = this.parsedCoverImageByComponentState;
    newBasicInfo.lang = newBasicInfo?.setLang;
    
    delete newBasicInfo['setLang'];
    delete newBasicInfo['locationList'];

    return firstValueFrom(this.store.dispatch(new ItineraryAction.UploadItineraryBlog<Partial<BasicInfoData>>(newBasicInfo, 'blog')));
  }

  private async addBasicInfo(): Promise<void> {

    const payload: Partial<BasicInfoData> = {
      id: 'untitled',
      title: this.navbarControl.navbarTitleState$$.value,
      content: this.componentState.subscription$$.value?.storyContent,
      coverImage: this.parsedCoverImageByComponentState,
      lang: {
        langCode: currentLang(),
        language: getLanguageName(),
        $$hashKey: "object:670",
        setLang: true,
        showLangDD: false
      },
      videos: [],
      images: [],
      vId: clientVID()
    };

    await firstValueFrom(this.store.dispatch(new ItineraryAction.UploadItineraryBlog<Partial<BasicInfoData>>(payload, 'blog')));
    const basicInfo = this.store.selectSnapshot(ItineraryState.basicInfo);

    if (basicInfo) {
      this.location.replaceState(`/${currentLang()}/compose/travel-story/${basicInfo.pageTitle}`);
      this.addBlogDataToUserState(basicInfo)
    }
  }

  private addBlogDataToUserState(basicInfo: Partial<BasicInfoData>): void {

    const userState = this.store.selectSnapshot(UserState.state);
    if (!userState) return;

    const value = { ...userState };
    const blog: UserBlogData = {
      datePublished: basicInfo.datePublished || '',
      id: basicInfo.id || '',
      pageTitle: basicInfo.pageTitle || '',
      title: basicInfo.title || ''
    };
    value.blogs = [ blog, ...value.blogs ]
    this.store.dispatch(new UserAction.SetBlogsAndItineraryData(value));
  }

}

@NgModule({
  declarations: [ BlogNavbarActionDirective ],
  providers:    [ AuthDialogActionService ],
  exports:      [ BlogNavbarActionDirective ]
})
export class BlogNavbarActionDirectiveModule { }
