import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { FeatureCardModule } from '@hiptraveler/ui/feature-card';

import { StoriesTabComponent } from './stories-tab.component';
import { NavigateToBlogDirective } from './navigate-to-blog.directive';

const MODULES = [
  CommonModule,
  TranslateModule,
  LazyLoadImageModule,
  NgxSkeletonLoaderModule
];

const LIBRARIES = [
  FeatureCardModule
];

const COMPONENTS = [
  StoriesTabComponent
];

const PIPES = [
  NavigateToBlogDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    StoriesTabComponent
  ]
})
export class StoriesTabModule { }
