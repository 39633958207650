import { Component, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';

import { TravelAgentsService as DataAccess } from '@hiptraveler/modules/search-page-root';
import { TravelAgentsService } from './travel-agents.service';
import { SendMessageDialogActionService } from '@hiptraveler/dialogs/send-message-dialog';
import { AppListenerService, profilePlaceholder } from '@hiptraveler/common';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { opacityFade, opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'travel-agents',
  templateUrl: './travel-agents.component.html',
  viewProviders: [ DataAccess, TravelAgentsService, AuthDialogActionService, SendMessageDialogActionService ],
  animations: [ opacityFade(), opacityFadeIn() ]
})
export class TravelAgentsComponent implements OnInit {

  profilePlaceholder: string = profilePlaceholder;

  constructor(
    public dataAccess: DataAccess,
    public service: TravelAgentsService,
    private appListener: AppListenerService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.dataAccess.getBrandCampaign();
    setTimeout(() => this.dataAccess.initializeDataAccess());
  }

  get skeletons$(): Observable<unknown[]> {
    return this.appListener.clientWidth$.pipe(
      map((clientWidth: number) => clientWidth > 1400 ? Array(3).fill(0) : Array(1).fill(0))
    );
  }

}
