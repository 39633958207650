import { AfterViewInit, Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';

import { StoryContentService } from './story-content.service';
import { MoreContentDetailsDialogActionService } from './more-content-details';

@Component({
  selector: 'story-content',
  templateUrl: './story-content.component.html',
  styleUrls: ['./story-content.component.scss'],
  host: { class: 'story-content' },
  viewProviders: [ StoryContentService, MoreContentDetailsDialogActionService ],
  encapsulation: ViewEncapsulation.None
})
export class StoryContentComponent implements AfterViewInit {

  @ViewChild('editable') editable: ElementRef<HTMLDivElement>;

  constructor(
    private element: ElementRef<HTMLElement>,
    public service: StoryContentService
  ) { }

  ngAfterViewInit(): void {

    this.service.initializeMediumEditor(this.editable);

    this.service.visibility$.subscribe((state: boolean) => {
      this.element.nativeElement.parentElement!.parentElement!.style.display = state ? 'flex' : 'none';
    });
  }

}
