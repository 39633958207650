import { Store } from "@ngxs/store";
import { HipTravelerRequestResponse } from "./common-api.interface";
import { AuthAction } from "@hiptraveler/data-access/auth";
import { getWindowRef, globalStateManagementStoreKey } from "@hiptraveler/common";
import { UserState } from "@hiptraveler/data-access/user";

interface ValidateResponseOption {
  /* If the specified value exists in the response object, the response object will be ignored and not validated. */
  ignoreKey?: string;
}

export function toQueryString(obj: Record<string, any>): string {
  const filteredObj: Record<string, string> = {};

  for (const [key, value] of Object.entries(obj)) {
    if (value !== null && value !== undefined && value !== '') {
      filteredObj[key] = value;
    }
  }

  return Object.entries(filteredObj)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}

export function validateResponse<T extends HipTravelerRequestResponse>(response: T, option?: ValidateResponseOption): void {
 
  const defaultErrorMessage = 'Something went wrong. Please try again.';
  const successField = response?.hasOwnProperty('success');
  
  if (response?.hasOwnProperty(option?.ignoreKey || '')) return;

  if (response?.status === 'FAILURE') throw { error: response.message, status: response.status };

  if (successField && !response?.success) throw { error: response?.message || defaultErrorMessage };

  if (successField && !response?.success && !Object.keys(response?.data || {}).length) throw { error: defaultErrorMessage };
 
  if (response.data?.['error']) throw { error: response.data.error };
  
  if (response?.['error']) throw { error: response.error };

}

export function handleErrorAndThrow<T extends HipTravelerRequestResponse>(response: T): void {
  const store = getWindowRef()?.[globalStateManagementStoreKey] as Store;
  const success = response?.hasOwnProperty('success') && response.success;
  const authenticated = !!store?.selectSnapshot(UserState.authenticated);
  const RESPONSE_ERROR_MESSAGE = 'Refresh Token has expired.';
  const RESPONSE_ERROR_STATUS = 'FAILURE';
  if (
    response?.error && response?.error === 'no_auth'
    || response?.status === RESPONSE_ERROR_STATUS && response?.message === RESPONSE_ERROR_MESSAGE
  ) {
    authenticated && store?.dispatch(new AuthAction.SignOutNoAuth);
  }
  if (
    !success && response?.error && response.error !== 'no_content'
    || (response?.status === RESPONSE_ERROR_STATUS && authenticated)
  ) {
    throw response;
  }
}
