import { subdomain, getWindowRef } from "@hiptraveler/common";

const CID_STORAGE_KEY: string = '_ht_cId';
const windowCIDKey: string = 'global_window_cid_value';

export function brandCampaignId(): string {

  const windowCID: string | undefined = getWindowRef()?.[windowCIDKey];

  const hostname = getWindowRef()?.location?.hostname;
  if (hostname?.includes('dev.hiptraveler')) { // TO DO: TEST CASE ONLY
    const subdomain = getWindowRef()?.localStorage.getItem('subdomain');
    if (subdomain === 'hiptraveler') return windowCID || 'hiptraveler';
    if (subdomain === 'visitjordan') return windowCID || '61a531c62841483f173e957b';
    if (subdomain === 'visitfiji') return windowCID || '66baaaed76ca6c6f00766ab6';
    // return '66466bde55806375191fd04d';
    return windowCID || 'hiptraveler';
  } // TO DO: TEST CASE ONLY

  if (windowCID) return windowCID;

  const brandCampaignId = windowCID || subdomain();

  if (!brandCampaignId) throw 'Client not found.';

  return brandCampaignId
}

export function setClientCId(value: string, storage: boolean = true): void {
  getWindowRef()[windowCIDKey] = value;
  storage && getWindowRef().localStorage.setItem(CID_STORAGE_KEY, value);
}

export function getClientCIdStorage(): string {
  return getWindowRef().localStorage.getItem(CID_STORAGE_KEY);
}
