import { getWindowRef } from "@hiptraveler/common";

export function encryptString(content: string): string {

  const _window = getWindowRef();
  if (!_window) return '';

  const encodedContent = new TextEncoder().encode(content);
  return _window.btoa(String.fromCharCode(...encodedContent));
}
