<ng-container *ngIf="service.brandCampaign$ | async">

  <div
    class="experience-finder-overlay"
    [class.transparent]="stateService.transparentBackdrop$ | async"
    (click)="service.experienceFinderPanelDisplay(false)" 
    *ngIf="(stateService.overlayState$ | async) && !(stateService.transparentBackdrop$ | async)">
  </div>
  
  <div
    class="experience-finder" experienceFinderHover
    [ngClass]="{
      active: stateService.overlayPositionState$ | async,
      'iframe-widget': service.iframeWidget$ | async
    }">
    
    <experience-finder-bubble (click)="service.experienceFinderPanelDisplay()"></experience-finder-bubble>
  
    <ng-template #experienceFinder></ng-template>

  </div>

  <div
    class="experience-finder-mobile"
    [ngClass]="{
      active: stateService.overlayState$ | async
    }">
    <ng-template #experienceFinderMobile></ng-template>
  </div>
  
</ng-container>
