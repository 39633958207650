import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ChatCompletionsService } from '@hiptraveler/core/openai';
import { ClientInfoStateService } from './client-info-state.service';
import { ScreenClientInfoService } from '../../features';

@Component({
  selector: 'client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss'],
  host: { class: 'experience-finder-ai--host-client-info experience-finder-ai--host-element' },
  viewProviders: [ ChatCompletionsService, ClientInfoStateService, ScreenClientInfoService ],
  encapsulation: ViewEncapsulation.None
})
export class ClientInfoComponent implements OnInit {

  constructor(
    private completions: ChatCompletionsService,
    public chatAI: ScreenClientInfoService,
    public service: ClientInfoStateService
  ) { }

  ngOnInit(): void {
    this.completions.authInitialize();
    this.service.clientDataObserver();
    this.service.formValueObserver();
    this.chatAI.observe();
  }

}
