import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Conversation } from '@hiptraveler/core/openai';
import { EFAStateServiceService } from '../../shared';

@Injectable()
export class MainSelectionService {

  constructor(
    public stateService: EFAStateServiceService
  ) { }

  get conversations$(): Observable<Conversation[]> {
    return this.stateService.conversations$;
  }

  get inputPending$(): Observable<boolean> {
    return this.stateService.inputPending$;
  }

}
