import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable, switchMap } from 'rxjs';
import { intersection, uniqBy } from 'lodash';

import { BrandInfo, Experience } from '@hiptraveler/data-access/api';
import { BrandState, BrandStateModel } from '@hiptraveler/data-access/brand';
import { EFAStateServiceService, CarouselFilterType, parseBrandState } from '..';

@Injectable()
export class CarouselService {

  constructor(
    private store: Store,
    private stateService: EFAStateServiceService
  ) { }

  get brandState(): BrandStateModel | null {
    let brandState = this.store.selectSnapshot(BrandState.state);
    brandState = parseBrandState(brandState)
    if (brandState?.experiences) {
      brandState.experiences = brandState.experiences.concat(this.stateService.locations$$.value as any);
      brandState.experiences = uniqBy(brandState.experiences, 'code');
    }
    return brandState;
  }

  get brandState$(): Observable<BrandStateModel | null> {
    return this.stateService.locations$.pipe(
      switchMap(locations => this.store.select(BrandState.state).pipe(
        map(parseBrandState),
        map(state => {
          if (state?.experiences) {
            state.experiences = state?.experiences?.concat(locations as any);
            state.experiences = uniqBy(state.experiences, 'code');
          }
          return state;
        })
      ))
    );
  }

  getDataByFilter(selectedFilter: CarouselFilterType, store?: 'select' | 'snapshot'): any {

    if (store === 'snapshot') {
      const state: Partial<BrandInfo> | null = this.brandState;
      return (state?.experiences || []).filter(e => e.category === selectedFilter) as Partial<Experience>[];
    }

    return this.brandState$.pipe(
      map((state: Partial<BrandInfo> | null) => (state?.experiences || []).filter(e => e?.category === selectedFilter))
    ) as Observable<Partial<Experience>[]>;
  }

  getDataFromResponseByFilter(selectedFilter: CarouselFilterType, response: string[]): string[] {
    const state: Partial<BrandInfo> | null = this.brandState;
    const fromState: string[] = (state?.experiences || []).filter(e => e.category === selectedFilter).map(e => e.code || '').filter(Boolean);
    return intersection(fromState, response);
  }

}
