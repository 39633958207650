import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

import { ExperienceFinderWidgetComponent } from './experience-finder-widget.component';
import { MatIconModule } from '@angular/material/icon';

const MODULES = [
  CommonModule,
  MatButtonModule,
  MatIconModule
];

const COMPONENTS = [
  ExperienceFinderWidgetComponent
];

@NgModule({
  imports: [
    MODULES,
    RouterModule.forChild([{
      path: '', component: ExperienceFinderWidgetComponent
    }])
  ],
  declarations: [
    COMPONENTS
  ]
})
export class ExperienceFinderWidgetModule { }
