import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MoreContentDetailsDialogService } from './more-content-details-dialog.service';
import { DialogData } from './more-content-details-dialog-action.service';

@Component({
  selector: 'app-more-content-details-dialog',
  templateUrl: './more-content-details-dialog.component.html',
  styleUrls: [`./more-content-details-dialog.component.scss`],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ MoreContentDetailsDialogService ]
})
export class MoreContentDetailsDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public ref: MatDialogRef<MoreContentDetailsDialogComponent>,
    public service: MoreContentDetailsDialogService
  ) { }

}
