import { Directive, NgModule, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs';

import { EFAStateServiceService, parseStateLocations } from '../shared';
import { BrandState } from '@hiptraveler/data-access/brand';

const LOCATIONS_CDN = 'https://d1npdhl5v1lqq4.cloudfront.net/assets/clients/locations/hip-default-locations.json';

@Directive({
  selector: '[locationCDN]'
})
export class LocationCdnDirective implements OnInit {

  constructor(
    private http: HttpClient,
    private store: Store,
    private stateService: EFAStateServiceService
  ) { }

  ngOnInit(): void {
    this.setupLocationCdn();
  }
  
  setupLocationCdn(): void {
    this.store.select(BrandState.state).pipe(
      filter(state => !!state && !state?.cLocations),
      switchMap(() => this.http.get(LOCATIONS_CDN).pipe(map((e: any) => e?.locations || []))),
      tap((e) => this.stateService.locations$$.next(parseStateLocations(e))),
      takeUntil(this.stateService.subscription$)
    ).subscribe();
  }

}

@NgModule({
  declarations: [ LocationCdnDirective ],
  exports:      [ LocationCdnDirective ]
})
export class LocationCdnDirectiveModule { }
