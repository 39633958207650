import { Store } from "@ngxs/store";

import { UserAction, UserState } from "@hiptraveler/data-access/user";
import { getWindowRef, globalStateManagementStoreKey } from "@hiptraveler/common";

export function updateUserItineraryByNewItineraryResponse(id: string, response: { newPageTitle: string, newTitle: string }): void {

  const store = getWindowRef()?.[globalStateManagementStoreKey] as Store;

  let itineraries = store.selectSnapshot(UserState.itineraries) || [];
  itineraries = itineraries.map(itinerary => {
    if (itinerary.id === id) {
      response?.newPageTitle && (itinerary.pageTitle = response.newPageTitle)
      response?.newTitle && (itinerary.title = response.newTitle);
    }
    return itinerary;
  }).slice(0);

  store.dispatch(new UserAction.UpdateItineraryData(itineraries));
}
