import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, combineLatest, delay, filter, firstValueFrom, take, takeUntil, tap } from 'rxjs';

import { BasicInfoData, UserItineraryData } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { AppListenerService, NavbarControlStateService, RequestCancellationService, currentLang, globalStoreItineraryDataKey, subdomain } from '@hiptraveler/common';
import { ItineraryStateService } from '@hiptraveler/features/itinerary';
import { SnackbarService } from '@hiptraveler/snackbar';

@Injectable()
export class ItineraryDataAccessService {

  subscription$ = new Subject<void>();
  resetStateFunc: () => void;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private appListener: AppListenerService,
    private navbarControl: NavbarControlStateService,
    private itineraryState: ItineraryStateService,
    private requestCancellation: RequestCancellationService,
    private snackbar: SnackbarService
  ) { }

  /**
   * Fetches itinerary data based on authentication status.
   * 
   * @returns {void}
   */
  async getItineraryData(resetState: () => void): Promise<void> {

    this.resetStateFunc = resetState;
    const data = this.store.selectSnapshot(ItineraryState.basicInfo);
    const actDate = this.store.selectSnapshot(ItineraryState.actDateArr) || [];

    if (
      !!data && !actDate.length
    ) return this.loadActivityDateMap();

    this.requestObserver();
    this.itineraryChecklistRequestObserver();
  }

  /**
   * Observes authentication state and manages requests.
   * - Monitors authentication state from the store.
   * - Cancels ongoing requests if authenticated and no dispatch is pending.
   * - Marks dispatch as pending.
   * - Triggers authenticated requests or fetches default data based on state.
   * 
   * @returns {void}
   */
  private requestObserver(): void {
    this.store.select(AuthState.authenticated).pipe(
      tap(() => {
        this.requestCancellation.cancelAllRequests();
        this.itineraryState.dispatchPending$$.next(true);
      }),
      delay(0),
      tap(value => value ? this.authenticatedRequest() : this.requestDefaultData()),
      take(1)
    ).subscribe();
  }

  /**
   * Observes user authentication and itinerary information. When the user is
   * authenticated and the itinerary has a valid ID, dispatches an action to fetch
   * the checklist reservations.
   * 
   * @returns {void}
   */
  private itineraryChecklistRequestObserver(): void {
    combineLatest([
      this.store.select(UserState.authenticated),
      this.store.select(ItineraryState.basicInfo) ]).pipe(
      filter(([authenticated, basicInfo]) => authenticated && !!basicInfo?.id),
      takeUntil(this.subscription$)
    ).subscribe(async value => {
      try {
        await firstValueFrom(this.store.dispatch(
          new ItineraryAction.GetChecklistReservations({ id: value[1]?.id! })
        ));
      } finally { }
    });
  }

  /**
   * Fetches itinerary data for authenticated users.
   * 
   * @returns {void}
   */
  private authenticatedRequest(): void {
    this.store.select(UserState.itineraries).pipe(
      filter(Boolean),
      take(1)
    ).subscribe((itineraries: UserItineraryData[]) => {
      this.appListener.previousUrl$$.value
        ? this.requestData(itineraries)
        : this.requestDefaultData();
    });
  }

  /**
   * Fetches default itinerary data for unauthenticated users.
   * 
   * @returns {Promise<void>}
   */
  private async requestDefaultData(): Promise<void> {
    const pageTitle = this.route.snapshot.params['itinerary'];
    if (this.checkDispatchValidity(pageTitle)) {
      this.snackbar.dismiss();
      return;
    }
    try {

      await Promise.all([
        new ItineraryAction.PartialResetItineraryState([ 'actDateMap', 'basicInfo', 'itineraryReservations' ]),
        new ItineraryAction.GetItineraryPageDataByPageTitle(pageTitle)
      ].map(e => firstValueFrom(this.store.dispatch(e))));

    } catch (response) {
      this.resetState();
    } finally {
      this.snackbar.dismiss();
      this.itineraryState.dispatchPending$$.next(false);
    }
  }

  /**
   * Fetches and processes itinerary data from various sources.
   * 
   * @param itineraries - An array of UserItineraryData objects representing available itineraries.
   * @returns A Promise that resolves when data fetching is complete, or rejects on error.
   */
  private async requestData(itineraries: UserItineraryData[]): Promise<void> {
    
    const itinerary = this.getItinerary(itineraries);
    const itineraryOwner = itineraries.find(e => e.pageTitle === itinerary?.pageTitle);

    if (!itinerary) return this.resetState({ errorMessage: 'Itinerary not found. Please try again.' });
    if (!itineraryOwner) return this.requestDefaultData();

    try {
      const itineraryCount = Math.ceil(itinerary.itiDays.length / 7);

      if (!itinerary.itiDays || this.checkDispatchValidity(itinerary?.pageTitle)) return;

      const promises = [
        new ItineraryAction.PartialResetItineraryState([ 'actDateMap', 'basicInfo', 'itineraryReservations' ]),
        new ItineraryAction.GetItineraryByPageTitle(itinerary.pageTitle),
        new ItineraryAction.GetItineraryTripByIdAndCount(itinerary.id, itineraryCount )
      ];

      subdomain() !== 'hiptraveler' 
      && promises.concat([ new ItineraryAction.GetTravelAgentList(itinerary.id) ] as any);
      
      await Promise.all(promises.map(
        e => firstValueFrom(this.store.dispatch(e))
      ));
      
    } catch (response) {
      this.resetState();
    } finally {
      this.snackbar.dismiss();
      this.itineraryState.dispatchPending$$.next(false);
    }
  }

  /**
   * Manually loads the activity date map result.
   * 
   * @returns {Promise<void>}
   */
  private async loadActivityDateMap(): Promise<void> {
    try {
      const pageTitle = this.route.snapshot.params['itinerary'];
      this.itineraryState.dispatchPending$$.next(true);
      await firstValueFrom(this.store.dispatch(new ItineraryAction.GetItineraryPageDataByPageTitle(pageTitle)));
    } catch (response) {
      this.resetState();
    } finally {
      this.snackbar.dismiss();
      this.itineraryState.dispatchPending$$.next(false);
    }
  }

  /**
   * Validate dispatch by comparing the requested pageTitle with the pageTitle in the state.
   * Prevent the request if the requested pageTitle matches the pageTitle in the state.
   * 
   * @param pageTitle - Itinerary basicInfo pageTItle response field value.
   * @returns Boolean.
   */
  private checkDispatchValidity(pageTitle: string): boolean {

    const basicInfo = this.store.selectSnapshot(ItineraryState.basicInfo);
    if (!basicInfo) return false;

    this.checkItineraryOwnershipByAuth(basicInfo);

    return basicInfo.pageTitle === pageTitle;
  }

  /**
   * Update the ownership field in the basic information.
   * 
   * @param basicInfo - Itinerary basic information object
   */
  private checkItineraryOwnershipByAuth(basicInfo: Partial<BasicInfoData>): void {
    const profileId = this.store.selectSnapshot(UserState.profileId);
    const owner = basicInfo.author?.authorTitle === profileId;
    this.store.dispatch(new ItineraryAction.ModifyBasicInfo({ owner }));
  }

  /**
   * Retrieves a specific itinerary from the provided list based on page title.
   * 
   * @param itineraries - An array of UserItineraryData objects.
   * @returns The matching itinerary object, or null if not found.
   */
  private getItinerary(itineraries: UserItineraryData[]): UserItineraryData | null {
    const store = this.appListener.getGlobalStore<UserItineraryData>(globalStoreItineraryDataKey);
    if (store) return store;
    if (!itineraries || !itineraries.length) return null;
    const pageTitle = this.route.snapshot.params['itinerary'];
    return itineraries.find(e => e.pageTitle === pageTitle) || null;
  }

  /**
   * Function that redirects the page to search page when a request endpoint fails
   * 
   * @param value - Catch block response object
   * @returns {Promise<void>}
   */
  private async resetState(value?: { errorMessage: string }): Promise<void> {
    setTimeout(() => this.resetStateFunc(), 1000);
    await this.router.navigate([ '/', currentLang(), 'search' ]);
    this.snackbar.open({ message: value?.errorMessage || 'Something went wrong. Please try again.', duration: 5000 });
  }

}
