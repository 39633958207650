
<post-action-button></post-action-button>
<div><br><br></div>

<ng-container *ngIf="service.basicInfo$ | async;else noTripDetails">

  <ng-container *ngIf="service.actDateMap$ | async">
    <trip-detail-content></trip-detail-content>
  </ng-container>

  <ng-container *ngTemplateOutlet="travelAgentsContent"></ng-container>

</ng-container>
<ng-template #noTripDetails>

  <ng-container *ngTemplateOutlet="travelAgentsContent"></ng-container>
  <experiences-content (actionClicked)="navigate($event)"></experiences-content>

</ng-template>

<ng-container *ngIf="service.activitiesContentSpaceVisibility$ | async"> <br><br> </ng-container>
<activities-content (actionClicked)="navigate($event)"></activities-content>

<ng-container *ngIf="connectVisibility">
  <ng-container *ngIf="service.connectContentVisibility$ | async">
    <ng-container *ngIf="service.connectContentSpaceVisibility$ | async"> <br><br> </ng-container>
    <connect-content></connect-content>
  </ng-container>
</ng-container>

<br><br><br>
<hotels-content (actionClicked)="navigate($event)"></hotels-content>
<br><br>
<restaurants-content (actionClicked)="navigate($event)"></restaurants-content>
<br><br>

<ng-template #travelAgentsContent>
  <travel-agents-content></travel-agents-content>
</ng-template>
