import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { filter, takeUntil } from 'rxjs';

import { ChatCompletionsService } from '@hiptraveler/core/openai';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { MainSelectionService } from './main-selection.service';
import { opacityFadeIn } from '@hiptraveler/animations';
import { CarouselService, EFAStateServiceService, ScreenView } from '../../shared';
import { ScreenMainSelectionService } from '../../features';

@Component({
  selector: 'main-selection',
  templateUrl: './main-selection.component.html',
  styleUrls: ['./main-selection.component.scss'],
  host: { class: 'experience-finder-ai--host-main-selection experience-finder-ai--host-element' },
  viewProviders: [ AuthDialogActionService, ChatCompletionsService, CarouselService, MainSelectionService, ScreenMainSelectionService ],
  animations: [ opacityFadeIn(2000) ],
  encapsulation: ViewEncapsulation.None
})
export class MainSelectionComponent implements OnInit {

  constructor(
    private completions: ChatCompletionsService,
    public stateService: EFAStateServiceService,
    private chatAI: ScreenMainSelectionService,
    public service: MainSelectionService
  ) { }

  ngOnInit(): void {
    
    this.completions.authInitialize();
    
    const stateService = this.service.stateService;

    this.chatAI.observe();

    stateService.screenViewValue$.pipe(
      filter(screen => [ ScreenView.welcome, ScreenView.mainSelection ].includes(screen)),
      takeUntil(stateService.subscription$)
    ).subscribe(() => {
      stateService.allowScroll$$.next('allow');
    });

    stateService.allowScroll$.pipe().subscribe(allowScroll => {
      console.log('@@@ c-allowScroll', allowScroll);
    });
  }

}
