<div class="agent-details--form-fields" [formGroup]="form">
  <div matRipple class="agent-details--form-social-link mat-elevation-z2" [socialMediaEvent]="[this,'tiktok']">
    <mat-icon svgIcon="registration-form-agent-details-social-media-link.tiktok-svg"></mat-icon>
    <p>{{ 'reg-form.tiktok-account' | translate }}</p>
    <mat-icon class="complete-svg" svgIcon="registration-form-agent-details-social-media-link.done-svg"></mat-icon>
  </div>
  <div matRipple class="agent-details--form-social-link mat-elevation-z2" [socialMediaEvent]="[this,'twitter']">
    <mat-icon svgIcon="registration-form-agent-details-social-media-link.x-svg"></mat-icon>
    <p>{{ 'reg-form.twitter-account' | translate }}</p>
    <mat-icon class="complete-svg" svgIcon="registration-form-agent-details-social-media-link.done-svg"></mat-icon>
  </div>
  <!-- <div class="agent-details--form-fields-break"></div> -->
  <!-- <div matRipple class="agent-details--form-social-link mat-elevation-z2" [socialMediaEvent]="[this,'facebook']">
    <mat-icon svgIcon="registration-form-agent-details-social-media-link.facebook-svg"></mat-icon>
    <p>{{ 'reg-form.facebook-account' | translate }}</p>
    <mat-icon class="complete-svg" svgIcon="registration-form-agent-details-social-media-link.done-svg"></mat-icon>
  </div> -->
  <div matRipple class="agent-details--form-social-link mat-elevation-z2" [socialMediaEvent]="[this,'instagram']">
    <mat-icon svgIcon="registration-form-agent-details-social-media-link.instagram-svg"></mat-icon>
    <p>{{ 'reg-form.instagram-account' | translate }}</p>
    <mat-icon class="complete-svg" svgIcon="registration-form-agent-details-social-media-link.done-svg"></mat-icon>
  </div>
  <div class="agent-details--form-fields-break"></div>
  <br>
  <mat-form-field underlined appearance="outline" formGroupName="social">
    <mat-label>Facebook</mat-label>
    <input matInput type="text" [placeholder]="'reg-form.facebook-account' | translate" name="facebook" formControlName="facebook">
  </mat-form-field>
  <!-- <mat-form-field underlined appearance="outline" formGroupName="social">
    <mat-label>Instagram</mat-label>
    <input matInput type="text" [placeholder]="'reg-form.instagram-account' | translate" name="instagram" formControlName="instagram">
  </mat-form-field> -->
  <mat-form-field underlined appearance="outline" formGroupName="social">
    <mat-label>Youtube</mat-label>
    <input matInput type="text" [placeholder]="'reg-form.youtube-channel' | translate" name="youtube" formControlName="youtube">
  </mat-form-field>
  <mat-form-field underlined appearance="outline" formGroupName="social">
    <mat-label translate="reg-form.website"></mat-label>
    <input matInput type="text" [placeholder]="'reg-form.website-url' | translate" name="website" formControlName="website">
  </mat-form-field>
  <mat-form-field underlined appearance="outline" formGroupName="social">
    <mat-label translate="reg-form.rss-link"></mat-label>
    <input matInput type="text" [placeholder]="'reg-form.add-rss-link' | translate" name="rss" formControlName="rss">
  </mat-form-field>
  
  <!-- <div class="agent-details--form-fields-break"></div></div> -->
