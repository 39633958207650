import { Directive, HostListener } from '@angular/core';

import { SocialApiPopupService } from '@hiptraveler/data-access/api';
import { SocialMediaType } from '@hiptraveler/features/registration-form';

@Directive({
  selector: '[socialMediaEvent]'
})
export class SocialMediaEventDirective {

  constructor(
    private socialApi: SocialApiPopupService
  ) { }

  @HostListener('socialClicked', ['$event'])
  hostClicked(type: SocialMediaType): void {
    switch (type) {
      case 'facebook': {
        this.socialApi.openPopupWindow('fb');
        return;
      }
      case 'instagram': {
        this.socialApi.openPopupWindow('insta');
        return;
      }
      case 'tiktok': {
        this.socialApi.openPopupWindow('tk');
        return;
      }
      case 'twitter': {
        this.socialApi.openPopupWindow('tw');
        return;
      }
    }
  }

}
