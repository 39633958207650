<ng-container *ngIf="(data | async)?.tourData as tourData;else noResultInformation">
  
  <ng-container *ngIf="tourData.reviews?.length">
    <hr>
    <div class="tour-review--data" *ngFor="let reviewData of tourData.reviews?.slice(0, 1);">
      <div class="tour-review--data-header" [@opacityFade]>
        <p>{{ reviewData.reviewer }}</p>
        <div class="ratings">
          <star-ratings [rating]="reviewData.rating" [size]="15" [offsetY]="-4"></star-ratings>
        </div>
      </div>
      <p class="tour-review--data-date">{{ reviewData.reviewDate | localDate }}</p>
      <p class="tour-review--data-content">{{ reviewData.review }}</p>
    </div>
  </ng-container>

  <ng-container *ngIf="tourData?.numRating as numRating">
    <div class="tour-review--information" [@opacityFade]>
      <star-ratings [rating]="tourData.overallRating || 0" [size]="20" [offsetY]="-4"></star-ratings>
      <p>{{ 'search-result.based-on' | translate }} <span class="tour-review--information-navigation"><span class="ht-digit">{{ numRating | toLocale }}</span> {{ 'search-result.reviews' | translate }}</span></p>
    </div>
  </ng-container>

</ng-container>
<ng-template #noResultInformation>
  <div class="tour-review--progress" *ngIf="!(data | async)">
    <mat-progress-spinner class="primary" diameter="60" mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>
