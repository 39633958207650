import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom, Observable, Subject } from 'rxjs';

import { SnackbarService } from '@hiptraveler/snackbar';
import { AppListenerService, dynamicRequestCancellationEndpointsKey, currentLang, preventRequestCancellation, getWindowRef } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class RequestCancellationService {

  private cancelSubject$$ = new Subject<void>();
  private dynamicCancellationSignal$$ = new Subject<void>();
  private searchCancelSubject$$ = new Subject<void>();
  private profileSearchCancelSubject$$ = new Subject<void>();

  constructor(
    private router: Router,
    private appListener: AppListenerService,
    private snackbar: SnackbarService
  ) { }
  
  get cancellationSignal$(): Observable<void> {
    return this.cancelSubject$$.asObservable();
  }

  get dynamicCancellationSignal$(): Observable<void> {
    return this.dynamicCancellationSignal$$.asObservable();
  }

  get searchCancellationSignal$(): Observable<void> {
    return this.searchCancelSubject$$.asObservable();
  }

  get profileSearchCancellationSignal$(): Observable<void> {
    return this.profileSearchCancelSubject$$.asObservable();
  }

  async cancelAllRequests(): Promise<void> {
    if (await this.preventCancellation()) return;
    
    this.cancelSubject$$.next();
    this.snackbar.dismiss();
  }

  cancelDynamicRequests(): void {
    this.dynamicCancellationSignal$$.next();
    this.snackbar.dismiss();
    setTimeout(() => sessionStorage.removeItem(dynamicRequestCancellationEndpointsKey), 1000);
  }

  async cancelAllSearchRequests(): Promise<void> {
    if (await this.preventCancellation()) return;

    this.searchCancelSubject$$.next();
    this.snackbar.dismiss();
  }

  cancelAllProfileSearchRequests(): void {
    this.profileSearchCancelSubject$$.next();
    this.snackbar.dismiss();
  }

  private async preventCancellation(): Promise<boolean> {

    const bypass = await this.itineraryBypass();
    if (getWindowRef()?.[preventRequestCancellation] || bypass) return true;

    const previousUrl = await firstValueFrom(this.appListener.previousUrl$);
    return [ previousUrl, this.router.url ].every(url => 
      [`/${currentLang()}`, `/${currentLang()}/search`].includes(url)
    );
  }

  private async itineraryBypass(): Promise<boolean> {
    const previousUrl = await firstValueFrom(this.appListener.previousUrl$);
    return ['itinerary', 'travel-story'].some(type =>
      previousUrl.includes(`/${currentLang()}/compose/${type}`)
      && this.router.url.includes(`/${currentLang()}/${type}`)
    );
  }

}
