import { Inject, Injectable, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, combineLatest, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs';

import { BasicInfoData } from '@hiptraveler/data-access/api';
import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { ComponentState, ComponentStateService, fromComposePageNotOwner } from '@hiptraveler/features/itinerary';
import { clientVID, currentLang, getWindowRef, NavbarControlStateService } from '@hiptraveler/common';
import { SnackbarService } from '@hiptraveler/snackbar';
import { syncPageTitle } from '../common';

@Injectable()
export class ComposeItineraryFormService implements OnDestroy {

  @Select(ItineraryState.basicInfo) basicInfo$: Observable<Partial<BasicInfoData> | null>;

  form: FormGroup = this.fb.group({
    storyTitle: [ '' ],
    storyLocation: [ '' ]
  });

  subscription$ = new Subject<void>();

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    public router: Router,
    public location: Location,
    private store: Store,
    private navbarControl: NavbarControlStateService,
    private componentState: ComponentStateService,
    private snackbar: SnackbarService
  ) { }

  ngOnDestroy(): void {
    this.form.reset();
    this.componentState.reset();
    this.subscription$.next();
  }

  componentObserver(): void {

    this.basicInfoChanges((response: Partial<BasicInfoData> | null) => {
      syncPageTitle(this.location, response?.pageTitle);
    });

    this.basicInfoChanges((response: Partial<BasicInfoData> | null) => {
      this.componentState.patch({ processing: !response });
      this.componentState.patch({ bannerProcessing: !response });
    });

    this.componentState.processing$.subscribe((state: boolean) => {
      this.form.get('storyTitle')?.[state ? 'disable' : 'enable']()
      this.form.get('storyLocation')?.[state ? 'disable' : 'enable']()
    });

    this.form.get('storyTitle')?.valueChanges.subscribe((value: string) => {
      this.navbarControl.navbarTitleState$$.next(value);
    });

    this.componentState.value$.pipe(
      map((componentState: ComponentState) => componentState!.storyTitle!),
      distinctUntilChanged(),
      tap((storyTitle: string) => {
        this.form.patchValue({ storyTitle });
        this.navbarControl.navbarTitleState$$.next(storyTitle);
      }),
      takeUntil(this.subscription$)
    ).subscribe();

    this.owner$.pipe(map(e => !e), filter(Boolean)).subscribe(() => {
      getWindowRef()[fromComposePageNotOwner] = '1';
      const pageTitle = this.location.path().replace(`/${currentLang()}/compose/itinerary/`, '');
      this.router.navigate([ currentLang(), 'itinerary', pageTitle ]);
    });
  }

  private basicInfoChanges(callback: (response: Partial<BasicInfoData> | null) => void): void {
    this.basicInfo$.pipe(
      takeUntil(this.subscription$)
    ).subscribe(callback.bind(this));
  }

  languageSelection(): void {
    this.snackbar.open({ message: 'You have English as your preferred language.' });
  }

  private get owner$(): Observable<boolean> {
    return combineLatest([
      this.store.select(UserState.profileId).pipe(filter(Boolean)),
      this.store.select(ItineraryState.basicInfo).pipe(filter(Boolean)),
    ]).pipe(
      map(([ profileId, basicInfo ]) => 
        basicInfo?.author?.authorTitle === profileId
        || clientVID() === basicInfo?.author?.authorProfId
      ),
      takeUntil(this.subscription$)
    );
  }

}
