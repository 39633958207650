import { Location } from '@angular/common';
import { pick } from 'lodash';

import { AddItineraryBoardResponse, BasicInfoData, SaveItineraryTripBlogResponse } from "@hiptraveler/data-access/api";
import { currentLang, getWindowRef, subdomain } from '@hiptraveler/common';

export function addItineraryBoardParsedResponse(response: AddItineraryBoardResponse, location: Location): Partial<BasicInfoData> {

  if (!response.data) return {};
 
  const responseContent = response.data.itiMap.basicInfo.content;
  const result = saveToTripParsedResponse(response.data.itiMap.basicInfo, location);
  result.content = responseContent;
  return result;
}

export function saveItineraryTripBlogParseResponse(response: SaveItineraryTripBlogResponse, location: Location): Partial<BasicInfoData> {

  if (!response?.basicInfo) return {}
  
  const responseContent = response.basicInfo.content;
  const result = saveToTripParsedResponse(response.basicInfo, location);
  result.content = responseContent;
  return result
}

function saveToTripParsedResponse(value: Partial<BasicInfoData>, location: Location): Partial<BasicInfoData> {

  const data = pick(value, [ 'id', 'title', 'pageTitle', 'userLikes', 'author', 'datePublished' ])

  const pageTitle = value?.pageTitle;
  const pageUrlParam = value?.type === 'itinerary' ? 'itinerary' : value?.type === 'blog' ? 'travel-story' : '';

  if (pageTitle && pageUrlParam) {
    location.replaceState(`/${currentLang()}/${pageUrlParam}/${data.pageTitle}`);
  }

  const subdomainParam = subdomain() === 'hiptraveler' ? '' : `${subdomain()}.`;
  // To do: make dynamic, dangerous if static;
  const domain = isTestEnv() ? `${subdomainParam}localhost:4200` : `${subdomainParam}hiptraveler.com`;

  return {
    ...data, copied: true, owner: true,
    canonicalUrl: `http://${domain}/${currentLang()}/${pageUrlParam}/${data.pageTitle}`,
    pageUrl: `http://${domain}/${currentLang()}/${pageUrlParam}/${data.pageTitle}`
  };
}

function isTestEnv(): boolean {

  const hostname = getWindowRef()?.location?.hostname;

  if (
    hostname?.includes('.web.app')
    || hostname?.includes('.firebaseapp.com')
    || hostname?.includes('localhost')
  ) return true;

  return false;
}
