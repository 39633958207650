import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { map, Observable, Subject, switchMap } from 'rxjs';

import { BasicInfoData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { currentLang, SearchPageControlStateService } from '@hiptraveler/common';

@Injectable()
export class TripDetailContentService implements OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store,
    private searchPageControl: SearchPageControlStateService
  ) { }

  private get pageTItle(): string {
    return this.store.selectSnapshot(ItineraryState.basicInfo)?.pageTitle || '';
  }

  get basicInfo$(): Observable<Partial<BasicInfoData> | null> {
    return this.store.select(ItineraryState.basicInfo).pipe(
      switchMap(basicInfo => this.searchPageControl.activityDate$.pipe(
        map(activityDate => activityDate ? basicInfo : null)
      ))
    );
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  navigateToItinerary(): void {
    this.router.navigate([ currentLang(), 'itinerary', this.pageTItle ]);
  }

}
