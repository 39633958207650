import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { environment } from '@hiptraveler/environments';

const routes: Routes = [
  {
    path: ':lang',
    loadChildren: () => import ('../modules/shell--search-page-root').then(m => m.SearchPageRootModule)
  },
  {
    path: ':lang',
    loadChildren: () => import ('../modules/shell--default-page-root').then(m => m.DefaultPageRootModule)
  },
  {
    path: ':lang',
    loadChildren: () => import ('../modules/shell--trip-view-page-root').then(m => m.TripViewPageRootModule)
  },
  {
    path: ':lang/compose',
    loadChildren: () => import ('../modules/page--compose').then(m => m.ComposeModule)
  },
  {
    path: ':lang/:cId/addtoFavorites/experience-finder/:vId/menu',
    loadChildren: () => import ('../modules/page--experience-finder-widget').then(m => m.ExperienceFinderWidgetModule),
  },
  {
    path: ':lang/:cId/addtoFavorites/experience-finder/:vId/login',
    loadChildren: () => import ('../modules/page--experience-finder-widget').then(m => m.ExperienceFinderWidgetModule),
  },
  {
    path: '**',
    redirectTo: `/en`
  }
];

environment.build === 'Production' || routes.unshift(...[
  {
    path: ':lang/hiptraveler-gpt',
    loadChildren: () => import('../modules/page--hiptraveler-gpt').then(m => m.HiptravelerGptModule)
  },
  {
    path: ':lang/expfinder-ai',
    loadChildren: () => import('../modules/page--experience-finder-widget').then(m => m.ExperienceFinderWidgetModule)
  }
]);

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: NoPreloading,
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'ignore',
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
