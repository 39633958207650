import { Injectable } from '@angular/core';

import { ChatCompletionsService } from '@hiptraveler/core/openai';
import { EFAStateServiceService, ScreenView, clientInfoPrompts } from '../../shared';

@Injectable()
export class ScreenClientInfoService {

  constructor(
    private completions: ChatCompletionsService,
    private stateService: EFAStateServiceService
  ) { }

  observe(): void {
    this.stateService.getInputMessageByScreen(ScreenView.clientInfo).subscribe(async (inputMessage: string) => {

      this.stateService.inputPending$$.next(true);
      this.stateService.pauseBrandInfoUXState$$.next(false);

      try {
        const chatCompletion = await this.completions.sendMessage(inputMessage, clientInfoPrompts);
        const response = chatCompletion.choices[0].message.content || '{}';
  
        const clientInfo = {
          adults: Number(JSON.parse(JSON.parse(response)).adults),
          children: Number(JSON.parse(JSON.parse(response)).children),
          infants: Number(JSON.parse(JSON.parse(response)).infants),
        };
  
        this.stateService.patchFormValue({ clientInfo });
      } catch (e) { }
      finally {
        this.stateService.inputPending$$.next(false);
        this.stateService.pauseBrandInfoUXState$$.next(true);
      }
    });
  }

}
