import { combineLatest, filter, map, Observable, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngxs/store';

import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { UserState } from '@hiptraveler/data-access/user';
import { clientVID, getWindowRef, globalStateManagementStoreKey } from '@hiptraveler/common';

export class ViewCommon {

  public subscription$ = new Subject<void>();
  public signedOut: boolean = false;

  public get owner$(): Observable<boolean> {
    const store = getWindowRef()?.[globalStateManagementStoreKey] as Store

    if (!store) {
      throw 'Class ViewCommon store is undefined';
    }

    return combineLatest([
      store.select(UserState.profileId),
      store.select(ItineraryState.basicInfo),
    ]).pipe(
      map(([ profileId, basicInfo ]) => 
        basicInfo?.author?.authorTitle === profileId
        || clientVID() === basicInfo?.author?.authorProfId
      ),
      takeUntil(this.subscription$)
    );
  }

  observeState(): void {

    this.signedOut = false;
    const store = getWindowRef()?.[globalStateManagementStoreKey] as Store

    store.select(UserState.authenticated).pipe(
      filter(e => !e),
      takeUntil(this.subscription$)
    ).subscribe(() => {
      this.signedOut = true;
    });
  }

}
