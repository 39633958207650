import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { CarouselGridService } from './carousel-grid.service';

@Component({
  selector: 'carousel-grid',
  templateUrl: './carousel-grid.component.html',
  styleUrls: ['./carousel-grid.component.scss'],
  host: { class: 'experience-finder-ai--host-carousel-grid experience-finder-ai--host-element' },
  viewProviders: [ CarouselGridService ],
  encapsulation: ViewEncapsulation.None
})
export class CarouselGridComponent implements OnInit {

  constructor(
    public service: CarouselGridService
  ) { }

  ngOnInit(): void {
    this.service.observeFormChanges()
  }

}
