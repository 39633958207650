import { ChangeDetectorRef, Injectable } from '@angular/core';

import { ChatCompletionsService } from '@hiptraveler/core/openai';
import { EFAStateServiceService } from '../../shared/state-service.service';
import { calendarPrompts, ScreenView } from '../../shared';

@Injectable()
export class ScreenCalendarService {

  constructor(
    private cdRef: ChangeDetectorRef,
    private completions: ChatCompletionsService,
    private stateService: EFAStateServiceService
  ) { }

  observe(): void {
    this.stateService.getInputMessageByScreen(ScreenView.calendar).subscribe(async (inputMessage: string) => {

      this.stateService.inputPending$$.next(true);
      this.stateService.pauseBrandInfoUXState$$.next(false);
      const chatCompletion = await this.completions.sendMessage(inputMessage, calendarPrompts);
      const response = chatCompletion.choices[0].message.content || '{}';

      this.stateService.patchFormValue({ dateRange: JSON.parse(response) });
      this.stateService.inputPending$$.next(false);
      this.stateService.pauseBrandInfoUXState$$.next(true);
      this.cdRef.detectChanges();
    });
  }

}
