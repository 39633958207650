
<button mat-icon-button class="host-close-panel" (click)="stateService.panelCloseTrigger$$.next()">
  <mat-icon>close</mat-icon>
</button>

<ng-container *ngIf="stateService.isMobile$ | async">
  <welcome-view textTypedId="main-selection-component"></welcome-view>
</ng-container>

<div class="main-selection--options" heightResizer>
  <div class="main-selection--option" matRipple optionAction="plan-a-trip"> <p>Plan a Trip</p> </div>
  <div class="main-selection--option" matRipple optionAction="my-profile"> <p>My Profile</p> </div>
  <div class="main-selection--option" matRipple optionAction="explore"> <p>Explore</p> </div>
</div>

<div class="main-selection--conversations">

  <div style="width: 10px;height: 6.25px;"></div>

  <div
    messageChatDynamic
    class="main-selection--conversation-chat"
    [innerHTML]="response.message"
    [ngClass]="{ sender: response.sender, receiver: !response.sender }"
    *ngFor="let response of service.conversations$ | async;">
  </div>

  <ng-container *ngIf="service.inputPending$ | async">
    <div class="main-selection--conversation-chat pending" [@opacityFadeIn]>
      <div class="loader"></div>
    </div>
  </ng-container>

</div>
