import * as API from "@hiptraveler/data-access/api";
import { NavbarControlStateService, currentLang, getWindowRef, requestEndpointParamValue } from "@hiptraveler/common";

export function setActiveNavbarButtons(response: API.AddItineraryResponse, navbarControl: NavbarControlStateService): void {

  const pageTitle = response.data?.itiMap?.basicInfo?.pageTitle || response.data?.itiMap?.pageTitle;
  const param = getWindowRef()?.[requestEndpointParamValue] === 'blog' ? 'travel-story' : 'itinerary';

  navbarControl.setActiveNavbarButtons([{
    name: 'navbar.action-text-open-trip',
    url: `/${currentLang()}/compose/${param}/${pageTitle}`,
    closeButton: true
  }]);
}
