import { Directive, ElementRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter, map, takeUntil, tap } from 'rxjs';
import { flatten } from 'lodash';

import { ActivityDateData, LocationData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { LeafletMap, LeafletMapControlStateService, calculateMiddlePoint, toGeoJSON } from '@hiptraveler/features/leaflet-map';
import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { AppListenerService, GeoJSONFeatureCollection, SearchResultData } from '@hiptraveler/common';
import { GeoJsonProp } from '../leaflet-map.interface';
import { BrandState } from '@hiptraveler/data-access/brand';

@Directive({
  selector: '[leafletMap]',
  providers: [ SearchResultDialogActionService ]
})
export class ItineraryDateViewMapDirective extends LeafletMap implements OnInit {

  pendingMarker: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store,
    private appListener: AppListenerService,
    private searchResultDialog: SearchResultDialogActionService,
    router: Router,
    elementRef: ElementRef<HTMLElement>,
    leafletControl: LeafletMapControlStateService
  ) {
    super(router, elementRef, leafletControl);
  }

  ngOnInit(): void {

    if (isPlatformServer(this.platformId)) return;

    this.appListener.mapVisibilityState$.pipe(takeUntil(this.subscription$)).subscribe(() => {
      this.setupMap();
    });
  }

  setupMap(): void {

    this.setMap();

    this.observePopupPane(this.element);

    this.observeCardData(({ adventures, foods, hotels }) => {

      const center = calculateMiddlePoint([ ...adventures, ...foods, ...hotels ].map(e => e.position));

      this.setMapView(center, 5);

      this.markerIcon?.adventureMarkerIcon
        && this.setGeoJSON(this.mapData(adventures), this.markerIcon.adventureMarkerIcon);
      this.markerIcon?.foodMarkerIcon
        && this.setGeoJSON(this.mapData(foods), this.markerIcon.foodMarkerIcon);
      this.markerIcon?.hotelMarkerIcon
        && this.setGeoJSON(this.mapData(hotels), this.markerIcon.hotelMarkerIcon);
    });

    this.store.select(BrandState.defaultCountry).pipe(
      filter(Boolean),
      tap((country: LocationData) => {
        this.clearAllLayers();
        this.setMapView(
          [ country?.position?.[1] || 0, country?.position?.[0] || 0 ],
          country?.position?.length ? 7 : 2
        );
      }),
      takeUntil(this.subscription$)
    ).subscribe();

    this.store.select(ItineraryState.basicInfo).pipe(
      filter(Boolean),
      takeUntil(this.subscription$)
    ).subscribe(basicInfo => {
      const coordinates = [
        +(basicInfo?.locationList?.[0]?.latitude || '0'),
        +(basicInfo?.locationList?.[0]?.longitude || '0')
      ];
      this.clearAllLayers();
      this.setMapView(coordinates);
    });

    this.leafletControl.activeSearchResultData$.pipe( /* Search result card hover observer */
      tap((data: SearchResultData) => {

        if (this.pendingMarker || ![...data.location.coordinates!].filter(Boolean).length) return;

        this.pendingMarker = true;
        const location: any = data?.location?.coordinates ?? [];
        this.setMapView(location);

        setTimeout(() => {
          this.pendingMarker = false;
          this.setMarker({ location, data, icon: this.searchPageMarkerIcon.highlight, popup: true });
        }, 500);
      }),
      takeUntil(this.subscription$)
    ).subscribe();

    this.selectedMarker$.pipe(
      map((id: string) => this.searchResultByStoreAndId(this.store, id)),
      tap(({ result, type }) => result && this.searchResultDialog.open(result, type))
    ).subscribe();
  }

  observeCardData(callback: (result: any) => void): void {
    this.store.select(ItineraryState.actDateArr).pipe(
      filter(Boolean),
      map((response: ActivityDateData[]) => ({
        hotels: flatten(response.map(e => e.HotelArray).filter(Boolean) || []),
        adventures: flatten(response.map(e => e.ImgArray).filter(Boolean)?.map(e => e?.filter(x => !x.imgCategory.includes('Food'))) || []),
        foods: flatten(response.map(e => e.ImgArray).filter(Boolean)?.map(e => e?.filter(x => x.imgCategory.includes('Food'))) || []).filter(e => !!e?.thumbnail)
      })),
      tap(callback.bind(this)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  mapData(data: any[]): GeoJSONFeatureCollection<GeoJsonProp> {
    return toGeoJSON(data.map(value => ({
      id: value?.id,
      title: value?.name,
      image: value?.thumbnail,
      locationName: value.location,
      address: {
        poi: value.name
      },
      location: {
        type: 'Point',
        coordinates: value?.position || []
      }
    })));
  };

}
