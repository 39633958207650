
<div class="client-info--highlight">

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
    <path d="M725-410v-120H605v-60h120v-120h60v120h120v60H785v120h-60Zm-365-82.31q-57.75 0-98.87-41.12Q220-574.56 220-632.31q0-57.75 41.13-98.87 41.12-41.13 98.87-41.13 57.75 0 98.87 41.13Q500-690.06 500-632.31q0 57.75-41.13 98.88-41.12 41.12-98.87 41.12ZM60-187.69v-88.93q0-29.38 15.96-54.42 15.96-25.04 42.66-38.5 59.3-29.07 119.65-43.61 60.35-14.54 121.73-14.54t121.73 14.54q60.35 14.54 119.65 43.61 26.7 13.46 42.66 38.5Q660-306 660-276.62v88.93H60Zm60-60h480v-28.93q0-12.15-7.04-22.5-7.04-10.34-19.11-16.88-51.7-25.46-105.42-38.58Q414.7-367.69 360-367.69q-54.7 0-108.43 13.11-53.72 13.12-105.42 38.58-12.07 6.54-19.11 16.88-7.04 10.35-7.04 22.5v28.93Zm240-304.62q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm0-80Zm0 384.62Z"/>
  </svg>

</div>

<div class="client-info--action mat-elevation-z4">

  <ng-container *ngFor="let client of service.clientData$ | async;let isLast = last;let i = index">

    <client-action [index]="i" [leftButtonLimit]="client.count < 1">
      <span infoHighlight>{{ client.category }}</span>
      <span infoDetails>{{ client.details }}</span>
      <span actionInfo>{{ client.count }}</span>
    </client-action>

    <div class="client-info--action-divider" *ngIf="!isLast"></div>

  </ng-container>

</div>
