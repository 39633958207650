import { Component, ElementRef, OnInit } from '@angular/core';

import { TripDetailContentService } from './trip-detail-content.service';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'trip-detail-content',
  templateUrl: './trip-detail-content.component.html',
  styleUrls: ['./trip-detail-content.component.scss'],
  viewProviders: [ TripDetailContentService ],
  animations: [ opacityFadeIn() ]
})
export class TripDetailContentComponent implements OnInit {

  constructor(
    private element: ElementRef<HTMLElement>,
    public service: TripDetailContentService
  ) { }

  ngOnInit(): void {
    this.service.basicInfo$.subscribe(basicInfo => {
      if (basicInfo) return;
      const host = this.element.nativeElement;
      const adjacent = host?.parentElement?.firstElementChild?.nextElementSibling as HTMLElement;
      if (host) host.style.display = 'none';
      if (adjacent) adjacent.style.display = 'none';
    });
  }

}
