import { getCheckInDateRange, toQueryString } from '@hiptraveler/data-access/api';
import { brandCampaignId, clientVID, currentLang } from '@hiptraveler/common';
import * as SearchApi from '../interfaces';

export function getLocationDataQueryString(data: SearchApi.SearchLocationDataActionQuery): string {
  return toQueryString({
    format: 'full',
    lang: currentLang(),
    pId: brandCampaignId(),
    cId: brandCampaignId(),
    ...data
  }); 
}

export function getAutocompleteLocationQueryString(data: SearchApi.AutocompleteLocationActionQuery): string {
  return toQueryString({
    cId: brandCampaignId(),
    ...data
  }); 
}

export function getHotelsQueryString(data: SearchApi.SearchHotelsActionQuery): string {
  return toQueryString({
    searchCnt: '1',
    sort: 'relevance',
    sortOrder: '1',
    rooms: '1',
    guests: '2',
    lang: currentLang(),
    pId: brandCampaignId(),
    cId: brandCampaignId(),
    vId: clientVID({ generate: true }),
    ...getCheckInDateRange(),
    ...data
  }); 
}

export function getAdventuresQueryString(data: SearchApi.SearchAdventuresActionQuery): string {
  return toQueryString({
    q: 'tours',
    searchCnt: '1',
    sortOrder: '1',
    lang: currentLang(),
    pId: brandCampaignId(),
    cId: brandCampaignId(),
    ...data
  }); 
}

export function getFoodsQueryString(data: SearchApi.SearchFoodsActionQuery): string {
  return toQueryString({
    q: 'all',
    searchCnt: '1',
    sortOrder: '1',
    lang: currentLang(),
    pId: brandCampaignId(),
    cId: brandCampaignId(),
    ...data
  }); 
}

export function getItinerariesQueryString(data: SearchApi.SearchItinerariesActionQuery): string {
  return toQueryString({
    searchCnt: '1',
    sortOrder: '1',
    lang: currentLang(),
    pId: brandCampaignId(),
    cId: brandCampaignId(),
    ...data
  }); 
}

export function getCommunitiesQueryString(data: SearchApi.SearchCommunitiesActionQuery): string {
  return toQueryString({
    searchCnt: '1',
    cId: brandCampaignId(),
    ...data
  }); 
}

export function getHotelsTermByQuery(data: SearchApi.SearchAdventuresTermByQuery): string {
  return toQueryString({
    subfilter: 'all',
    lang: currentLang(),
    pId: brandCampaignId(),
    cId: brandCampaignId(),
    vId: clientVID({ generate: true }),
    ...getCheckInDateRange(),
    ...data
  }); 
}

export function getAdventuresTermByQuery(data: SearchApi.SearchAdventuresTermByQuery): string {
  return toQueryString({
    subfilter: 'all',
    lang: currentLang(),
    pId: brandCampaignId(),
    cId: brandCampaignId(),
    ...data
  }); 
}

export function getFoodsTermByQuery(data: SearchApi.SearchAdventuresTermByQuery): string {
  return toQueryString({
    subfilter: 'all',
    lang: currentLang(),
    pId: brandCampaignId(),
    cId: brandCampaignId(),
    ...data
  }); 
}
