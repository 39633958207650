import { Component } from '@angular/core';

import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';

@Component({
  selector: 'experience-finder-bubble',
  template: `
    <div class="experience-finder--bubble mat-elevation-z6" [class.active]="stateService.overlayState$ | async" matRipple>
      <ng-container *ngIf="stateService.overlayState$ | async;else notOpened">
      
        <mat-icon>close</mat-icon>
      
      </ng-container>
      <ng-template #notOpened>
        
        <ng-container *ngIf="stateService.processing$$ | async;else defaultUI">
          <mat-progress-spinner mode="indeterminate" theme="white" diameter="30" strokeWidth="4"></mat-progress-spinner>
        </ng-container>
        <ng-template #defaultUI>
          <mat-icon svgIcon="experience-finder-bubble.svg-icon"></mat-icon>
        </ng-template>

      </ng-template>
    </div>
  `,
  styleUrls: ['./bubble.component.scss']
})
export class BubbleComponent {

  constructor(
    public stateService: ExperienceFinderStateService
  ) { }

}
