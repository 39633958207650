import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { BrandState } from '@hiptraveler/data-access/brand';
import { AppListenerService, currentLang, pendingAuthProcessKey, promiseDelay } from '@hiptraveler/common';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';

@Component({
  selector: 'bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  styleUrls: ['./bottom-navbar.component.scss'],
  host: { class: 'site-navigation-bottom-navbar-host' },
  viewProviders: [ AuthDialogActionService ],
  encapsulation: ViewEncapsulation.None
})
export class BottomNavbarComponent implements OnInit, OnDestroy {

  @Output() visibilityChanged = new EventEmitter<string>();
  authHandle$ = new Subject<void>();
  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private router: Router,
    private store: Store,
    private appListener: AppListenerService,
    private authDialog: AuthDialogActionService,
    private stateService: ExperienceFinderStateService
  ) { }

  ngOnInit(): void {
    this.appListener.bottomNavbarVisibility$.pipe(takeUntil(this.subscription$)).subscribe((state: boolean) => {
      if (state) {
        this.element.nativeElement.style.display = 'flex';
        this.visibilityChanged.emit('block');
      } else {
        this.element.nativeElement.style.display = 'none';
        this.visibilityChanged.emit('none');
      }
    });

    this.expfinderOpened$.subscribe(async (state: boolean) => {
      state || await promiseDelay(150);
      this.visibilityChanged.emit(state ? 'none' : 'block');
    });
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  get expfinderOpened$(): Observable<boolean> {
    return this.stateService.overlayState$
  }

  navigate(option: 'explore' | 'trips' | 'profile'): void {
    switch (option) {
      case 'explore': {
        const brandCampaign = this.store.selectSnapshot(BrandState.brandCampaign);
        const link = brandCampaign?.campaignCustom?.navHeaders?.find(e => e.category === 'All')?.link || 'search';
        this.router.navigateByUrl(`${currentLang()}/${link}`);
        break;
      }
      case 'trips': {
        break;
      }
      case 'profile': {
        if (!this.store.selectSnapshot(UserState.authenticated)) {
          this.resetAuthHandle();
          const emitHandleKey = 'bottomNavbarProfileNavigation';
          this.store.selectSnapshot(AuthState.authenticated) || this.openDialog(emitHandleKey);
          this.appListener.globalSignalListener(emitHandleKey).pipe(
            tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
            takeUntil(this.authHandle$)
          ).subscribe(() => this.navigate('profile'));
          return;
        }
        const profile = this.store.selectSnapshot(UserState.state)!.uTitle!;
        this.router.navigateByUrl(`/${currentLang()}/profile/${profile}/about`);
        break;
      }
    }
  }

  openAddItineraryDialog(): void {
    this.stateService.displayState$$.next(!this.stateService.overlayState$$.value);
  }

  private resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

  private openDialog(emitHandleKey: string): void {
    this.appListener.bottomNavbarVisibility$$.next(false)
    const ref: MatDialogRef<any, any> = this.authDialog.open('login', emitHandleKey) as any;
    ref.beforeClosed().subscribe(() => this.appListener.bottomNavbarVisibility$$.next(true));
  }

}
