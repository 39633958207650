import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarouselExperiencesComponent } from './carousel-experiences.component';
import { PoiSelectCarouselModule } from '../../components/poi-select-carousel';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  PoiSelectCarouselModule
];

const COMPONENTS = [
  CarouselExperiencesComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    CarouselExperiencesComponent
  ]
})
export class CarouselExperiencesModule { }
