import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SocialLoginModule } from '@abacritt/angularx-social-login';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { AppDirectivesModule } from './app-directives';
import { AppStoreModule } from './app-store';
import { FilestackCoreModule } from '@hiptraveler/core/filestack';
import { ExperienceFinderAIModule, SiteNavigationModule, FooterNavigationModule, CookiePolicyBannerModule } from '../features';

import { AppProviders } from './app-providers';
import { AppInterceptors } from './app-interceptors';
import { environment as env } from '@hiptraveler/environments';
import { appId } from '@hiptraveler/common';

@NgModule({
  bootstrap: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSnackBarModule,
    LazyLoadImageModule,

    AppRoutingModule,
    AppDirectivesModule,
    AppStoreModule,
    ExperienceFinderAIModule,
    SiteNavigationModule,
    FooterNavigationModule,
    CookiePolicyBannerModule,
    SocialLoginModule,

    // Filestack Module
    FilestackCoreModule.forRoot({
      apiKey: env.apiKey.filestack
    }),

    // Translate Module
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AppProviders,
    AppInterceptors
  ],
  declarations: [
    AppComponent
  ]
})
export class AppModule {}
