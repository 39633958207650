
<div class="summary" [class.has-items]="(service.summaryItems$ | async)?.length">

  <ng-container *ngIf="requesting;else results">
    <div class="summary--item" style="background: none;" [@opacityFadeIn]>
      <div class="loader"></div>
    </div>
  </ng-container>

  <ng-template #results>

    <ng-container *ngIf="!(service.summaryItems$ | async)?.length">
      <summary-data-selection [@opacityFadeIn]></summary-data-selection>
    </ng-container>

    <ng-container *ngFor="let summaryItem of service.summaryItems$ | async;let i = index">
      <div class="summary--item" [@opacityFadeIn]>
    
        <p class="summary--item-text">{{ summaryItem['output'] }}</p>
    
        <div class="summary--item-action" matRipple (click)="stateService.removeSummaryItem(i)">
          <mat-icon>close</mat-icon>
        </div>
    
      </div>
    </ng-container>
  </ng-template>

</div>
