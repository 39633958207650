import { SaveAmbassadorDto, SaveTravelAgentDto, SocialMediaMap } from "@hiptraveler/data-access/api";
import { isNull, omit, omitBy } from 'lodash';

import { ProfileState } from "@hiptraveler/data-access/profile";
import { getWindowRef, globalStateManagementStoreKey } from "@hiptraveler/common";

export function parseSaveAmbassadorPayload(value: SaveTravelAgentDto): SaveAmbassadorDto {

  const payload: SaveAmbassadorDto = omit(value, [ 'active', 'certId', 'certType', 'social', 'membership' ]) as any;

  payload.id = getWindowRef()[globalStateManagementStoreKey].selectSnapshot(ProfileState.ambassador)?.id || payload.id;

  return {
    ...payload,
    trExp: value.trExp.desc,
    social: omitBy({
      website: value.social?.website,
      rss: value.social?.rss,
      tk: splitSocial(value.social.tiktok),
      tw: splitSocial(value.social.twitter),
      insta: splitSocial(value.social.instagram),
      fb: socialObject(value.social.facebook, 'facebook'),
      yt: socialObject(value.social.youtube, 'youtube')
    }, isNull)
  };
}

function splitSocial(socialString: string): SocialMediaMap | null {
  const [handle, id] = socialString?.split('::') || ['', ''];
  return handle && id ? { handle, id } : null;
}

function socialObject(handle: string, social: string): SocialMediaMap {
  return { handle, id: social };
}
