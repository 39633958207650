<div id="app-root--host" appRequiredState appI18nSetup appClientWidthListener appScrollToViewListener appCalendarI18nListener appCustomGoogleMapAutocompleteState appMetatag>

  <ng-container *ngIf="appListener.languageLoadState$ | async">
    
    <app-site-navigation *ngIf="appListener.appSiteNavVisibility$ | async"></app-site-navigation>
    <experience-finder-ai *ngIf="appListener.experienceFinderVisibility$ | async"></experience-finder-ai>
  
    <div id="app-root--router-outlet" layoutStyles>
      <ng-container *ngIf="!(service.cachePending$ | async)">
        <router-outlet></router-outlet>
      </ng-container>
    </div>
   
    <app-footer-navigation *ngIf="appListener.appFooterVisibility$ | async"></app-footer-navigation>
  
    <cookie-policy-banner></cookie-policy-banner>
  
  </ng-container>

</div>
