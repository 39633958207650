import { Directive, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Actions, ofActionCompleted, Store } from "@ngxs/store";
import { delay, filter, firstValueFrom, Subject, take, takeUntil, tap } from 'rxjs';

import { CoverImage } from "@hiptraveler/data-access/api";
import { AuthAction, AuthState } from "@hiptraveler/data-access/auth";
import { UserState } from "@hiptraveler/data-access/user";
import { ItineraryAction, ItineraryState } from "@hiptraveler/data-access/itinerary";
import { ComponentStateService } from "@hiptraveler/features/itinerary";
import { AUTH_DIALOG_ID, AuthDialogActionService } from "@hiptraveler/dialogs/auth-dialog";
import { PROMPT_DIALOG_ID } from "@hiptraveler/dialogs/prompt-dialog";
import { SEARCH_RESULT_ID } from "@hiptraveler/dialogs/search-result-dialog";
import { AppListenerService, currentLang, getWindowRef, NavbarControlStateService, pendingAuthProcessKey, requestEndpointParamValue, SearchPageControlStateService } from "@hiptraveler/common";

@Directive()
export class ItineraryActionDirective {

  subscription$ = new Subject<void>();
  authHandle$ = new Subject<void>();
  authenticationObserverHandler: boolean;

  constructor(
    protected router: Router,
    private dialog: MatDialog,
    protected store: Store,
    private actions$: Actions,
    protected appListener: AppListenerService,
    private authDialog: AuthDialogActionService,
    protected navbarControl: NavbarControlStateService,
    protected searchPageControl: SearchPageControlStateService,
    protected componentState: ComponentStateService
  ) { }

  @HostListener('document:keydown.escape', ['$event']) 
  handleEscape() {
    if (
      this.dialog.getDialogById(AUTH_DIALOG_ID)?.id
      || this.dialog.getDialogById(SEARCH_RESULT_ID)?.id
      || this.dialog.getDialogById(PROMPT_DIALOG_ID)?.id
    ) return;
    this.exitFocusMode();
  }

  /**
   * Set up a listener for a navbar button signal and execute a callback when the signal is emitted.
   */
  navbarButtonListener(signal: string, method: () => void): void {
    this.appListener.globalSignalListener(signal).pipe(
      tap(method.bind(this)),
      takeUntil(this.subscription$)
    ).subscribe();
  }
  
  /**
   * Exit focus mode and navigate back to the previous page.
   */
  async exitFocusMode(): Promise<void> {
    this.appListener.previousUrl$.pipe(
      filter(e => e !== this.router.url),
      tap((previousUrl: string) => this.router.navigateByUrl(previousUrl || `/${currentLang()}`)),
      take(1)
    ).subscribe();
  }

  observeDispatchSuccess(): void {
    this.actions$.pipe(
      ofActionCompleted(ItineraryAction.UpdateItineraryActivity, AuthAction.RefreshSessionWithCookies),
      delay(1000), take(3)
    ).subscribe(() => {
      this.searchPageControl.featureCardProcessing$$.next(false);
      this.navbarControl.navbarToolbarActionComplete$$.next();
    });
  }

  /**
   * Navigate the user to the previous URL or the root page if the previous URL is null upon every user sign-out.
   */
  authenticationObserver(callback: () => void): void {

    setTimeout(() => {
      this.authenticationObserverHandler = true;
    }, 1000);

    this.store.select(AuthState.authenticated).pipe(
      filter(e => !e),
      tap(() => {
        if (
          !this.store.selectSnapshot(UserState.authenticated)
            && !!this.store.selectSnapshot(ItineraryState.basicInfo)
            && !!this.searchPageControl.activityDate$$.value
        ) return;
        this.authenticationObserverHandler && callback();
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  authTrigger(emitHandleKey: string): boolean {
    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(() => {
        const buttonClass = '.navbar--action-button';
        const button = document.body.querySelectorAll(buttonClass).item(1);
        button && button.dispatchEvent(new Event('click'));
      });
      setTimeout(() => this.navbarControl.navbarToolbarActionComplete$$.next(), 500);
      return true;
    }
    return false;
  }

  /**
   * Update the order of itineraries in the store and dispatch a save action.
   */
  async updateOrderUpdate(updateOrderItineraries: string): Promise<void> {
    const param = getWindowRef()[requestEndpointParamValue];
    return firstValueFrom(this.store.dispatch(new ItineraryAction.UpdateItineraryActivity({
      action: 'update-order', param,
      days: JSON.parse(updateOrderItineraries),
      lang: currentLang(),
      id: this.store.selectSnapshot(ItineraryState.basicInfo)?.id || ''
    })));
  }

  resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

  /**
   * Returns the object value of the cover image payload field.
   */
  get parsedCoverImageByComponentState(): Partial<CoverImage> {
    const value = { ...this.componentState.subscription$$.value };
    return value?.bannerAttribution ? {
      // Object condition: Image source from location list
      imgUrl: value?.bannerImage?.url,
      imgThumbnail: value?.bannerImage?.url,
      mobileUrl: value?.bannerImage?.url,
      originalUrl: value?.bannerImage?.url,
      attribution: value?.bannerAttribution,
      hasCoverImage: true
    } : value?.bannerImage?.handle ? {
      // Object condition: Upload photo from Filestack API
      imgUrl: `https://cdn.filestackcontent.com/resize=width:1024,fit:scale/auto_image/compress/${value.bannerImage.handle}`,
      imgThumbnail: `https://cdn.filestackcontent.com/resize=width:400,height:400,fit:crop/auto_image/compress/${value.bannerImage.handle}`,
      mobileUrl: `https://cdn.filestackcontent.com/resize=width:400,height:500,fit:scale/auto_image/compress/${value.bannerImage.handle}`,
      originalUrl: value?.bannerImage?.url,
      attribution: "",
      hasCoverImage: true
    } : value?.bannerImage?.url ? {
      // Object condition: Has existing image URL values
      imgUrl: value?.bannerImage?.url,
      imgThumbnail: value?.bannerImage?.url,
      mobileUrl: value?.bannerImage?.url,
      originalUrl: value?.bannerImage?.url,
      attribution: "",
      hasCoverImage: true
    } : {
      // Object condition: Default object
      imgUrl: "https://www.hiptraveler.com/assets/img/contactimg.webp",
      imgThumbnail: "https://www.hiptraveler.com/assets/img/contactimg.webp",
      mobileUrl: "https://www.hiptraveler.com/assets/img/contactimg.webp",
      originalUrl: "https://www.hiptraveler.com/assets/img/contactimg.webp",
      attribution: "",
      hasCoverImage: true
    }
  }
  
}