import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { ProfileStateService } from '../common';

@Directive({
  selector: '[navbarAppendHighlight]'
})
export class NavbarAppendHighlightDirective implements AfterViewInit, OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private stateService: ProfileStateService
  ) { }

  ngAfterViewInit(): void {
    this.routeNavigationEndObserver();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  routeNavigationEndObserver(): void {
    this.stateService.profileTabSelection$$.asObservable().pipe(
      takeUntil(this.subscription$)
    ).subscribe((activeSelection: string) => {

      const element = this.element.nativeElement;
      const elementChildren: HTMLElement[] = Array.from(element.firstElementChild?.children || []) as any;

      elementChildren.forEach((element: HTMLElement) => {
        const selection = element.attributes.getNamedItem('data-url')?.value.split('/').reverse()[0];
        if (selection === activeSelection) {
          this.renderer.addClass(element, 'active');
        } else {
          this.renderer.removeClass(element, 'active');
        }
      });
    });
  }

}
