import { Injectable, Provider } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { filter, firstValueFrom, from, Observable, switchMap } from 'rxjs';

import { BrandState } from '@hiptraveler/data-access/brand';
import { SearchAction, SearchState } from '@hiptraveler/data-access/search';
import { dynamicRequestCancellationEndpointsKey, getWindowRef, HT_SEARCH_LOCATION_KEY, objectToQueryString, queryStringToObject, removeCircularReferences, requestLocationPendingKey, SearchLocationData, SearchLocationService } from '@hiptraveler/common';
import { LocationData, SearchLocationDataActionQuery } from '@hiptraveler/data-access/api';

interface RequestString {
  url: string;
  payload: Record<string, string>
};

const searchEndpoints: string[] = [
  '/trip-planner/search/hotels',
  '/trip-planner/search/Itineraries/location',
  '/trip-planner/search/Adventure/location',    
  '/trip-planner/search/Food/location',  
  '/trip-planner/search/community'
];

@Injectable()
export class _SearchRequestInterceptor implements HttpInterceptor {

  constructor(
    private store: Store,
    private searchLocation: SearchLocationService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const searchRequest: boolean = searchEndpoints.some((url: string) => request.url.includes(url));

    if (!searchRequest) return next.handle(request);

    if (this.searchLocation?.placename) {
      return from(this.handleLocationByUrl()).pipe(
        switchMap(locationId => {
          const { url, payload } = this.requestString(request, locationId);
          return next.handle(request.clone({ url: `${url}?${objectToQueryString(payload)}` }));
        })
      );
    }
    
    return next.handle(request);
  }

  private async handleLocationByUrl(): Promise<string | null> {

    if (getWindowRef()?.[requestLocationPendingKey]) return null;

    const locationData = this.store.selectSnapshot(SearchState.locationData);
    const locationId = this.searchLocation.currentValue?.locId || '-';

    if (this.searchLocation.currentValue?.name === locationData?.city) return locationId;

    try {

      getWindowRef()[requestLocationPendingKey] = '1';
      sessionStorage.setItem(dynamicRequestCancellationEndpointsKey, JSON.stringify([ '/trip-planner/search/location' ]));

      const brandState = await firstValueFrom(this.store.select(BrandState.state).pipe(filter(Boolean)));
      brandState?.hasCustomLocations && await firstValueFrom(this.store.select(BrandState.locations).pipe(filter(Boolean)));
      await firstValueFrom(this.store.dispatch(new SearchAction.GetLocationDataByQuery(this.locationData)));     

      return locationId;
    }
    catch(e) { return null; }
    finally  { getWindowRef()[requestLocationPendingKey] = undefined; }
  }

  private get locationData(): SearchLocationDataActionQuery {
    const searchLocation = this.searchLocation.data;
    const brandLocation = this.locationQuery.brandLocation;
    return {
      q: searchLocation?.location || brandLocation?.formattedAddr || this.locationQuery.placename,
      locId: searchLocation?.locId || brandLocation?.id || ''
    };
  }

  private get locationQuery(): { placename: string, brandLocation: LocationData | null } {
    const placename = decodeURIComponent(this.searchLocation?.placename || '');
    const brandLocation = this.store.selectSnapshot(BrandState.locations)?.find(e => e.city === placename) || null;
    return { placename, brandLocation }
  }

  private requestString(request: HttpRequest<unknown>, locationId: string | null): RequestString {

    const [ url, param ] = request.url.split('?');
    const payload = { ...queryStringToObject(param) } as any;

    payload['locId'] = locationId || payload['locId'];

    return { url, payload };
  }

  private syncLocationStorageData(locationId: string | null): void {
    const storageLocation = getWindowRef()?.localStorage?.getItem(HT_SEARCH_LOCATION_KEY);

    if (storageLocation && locationId) {
      const storageLocationData = JSON.parse(storageLocation) as SearchLocationData;
      storageLocationData.locId = locationId;
      getWindowRef()?.localStorage?.setItem(HT_SEARCH_LOCATION_KEY, JSON.stringify(storageLocationData, removeCircularReferences()));
    }
  }

}

export const SearchRequestInterceptor: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: _SearchRequestInterceptor,
  multi: true,
}
