import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { AuthAction, AuthState } from '@hiptraveler/data-access/auth';
import { UserState, UserStateModel } from '@hiptraveler/data-access/user';
import { BrandState } from '@hiptraveler/data-access/brand';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { NavbarControlStateService, WINDOW, authenticatedProfilePlaceholder, currentLang } from '@hiptraveler/common';

@Component({
  selector: 'profile-selection',
  templateUrl: './profile-selection.component.html',
  styleUrls: ['./profile-selection.component.scss'],
  host: { class: 'feature-profile-selection' },
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ AuthDialogActionService ]
})
export class ProfileSelectionComponent {

  @Select(AuthState.authenticated) authenticated$: Observable<boolean>;
  @Select(BrandState.hiptravelerBrand) hiptravelerBrand$: Observable<unknown>;
  @Select(UserState.state) userState$: Observable<UserStateModel | null>;
  @Select(UserState.authenticated) userAuthenticated$: Observable<boolean>;

  @Input() className: string;
  @Input() divider: string;

  authenticatedProfilePlaceholder: string = authenticatedProfilePlaceholder;

  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private store: Store,
    private authDialog: AuthDialogActionService,
    private controlState: NavbarControlStateService
  ) {
    // setTimeout(() => this.openAuthDialog()); // -- For testing only / To do: Remove as soon as you are finish with the login module
  }
  
  openAuthDialog(): void {
    this.authDialog.open();
  }

  navigateToProfile(): void {
    const userId = this.store.selectSnapshot(UserState.state)?.uTitle;
    if (!userId) return;
    this.router.navigate([ currentLang(), 'profile', userId, 'stories' ]);
    this.controlState.navbarProfileClicked$$.next();
  }

  signOut(): void {
    this.store.dispatch(new AuthAction.SignOut)
  }

  exploreHiptraveler(): void {
    this.window.open('https://www.hiptraveler.com/en', '_break');
  }

}
