import { ActionType, getActionTypeFromInstance, NGXS_PLUGINS } from "@ngxs/store";

import * as ApiAuthAction from "../auth";
import { authStateDefaults } from "../auth.state";
import { userStateDefaults } from "@hiptraveler/data-access/user";
import { getWindowRef, HipTravelerWebStateModel, signoutAccessToken } from "@hiptraveler/common";

function logoutPlugin(state: HipTravelerWebStateModel, action: ActionType, next: any) {

  const condition = getActionTypeFromInstance(action) === ApiAuthAction.SignOut.type
    || getActionTypeFromInstance(action) === ApiAuthAction.SignOutNoAuth.type;

  if (state.state_auth?.accessToken) {
    getWindowRef()[signoutAccessToken] = state.state_auth?.accessToken;
  }

  if (condition) {

    if (state.state_profile) {
      state.state_profile.selfProfile = false;
      state.state_profile.followStatus = false;
    }

    state = {
      state_auth: authStateDefaults,
      state_user: userStateDefaults,
      state_brand: state.state_brand,
      state_itinerary: state.state_itinerary,
      state_profile: state.state_profile,
      state_search: state.state_search,
      router: state.router
    };
  }

  return next(state, action);
}

export const LogoutPluginProvider = {
  provide: NGXS_PLUGINS,
  useValue: logoutPlugin,
  multi: true
};
