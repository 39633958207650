import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, combineLatest, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs';

import { BasicInfoData } from '@hiptraveler/data-access/api';
import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { ComponentState, ComponentStateService, fromComposePageNotOwner } from '@hiptraveler/features/itinerary';
import { NavbarControlStateService, SearchLocationData, clientVID, currentLang, getWindowRef } from '@hiptraveler/common';
import { SnackbarService } from '@hiptraveler/snackbar';
import { syncPageTitle } from '../common';

@Injectable()
export class ComposeBlogFormService implements OnDestroy {
  
  @Select(ItineraryState.basicInfo) basicInfo$: Observable<Partial<BasicInfoData> | null>;

  form: FormGroup = this.fb.group({
    storyTitle: [ '' ],
    storyLocation: [ '' ]
  });
  
  subscription$ = new Subject<void>();

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    public router: Router,
    public location: Location,
    public store: Store,
    public navbarControl: NavbarControlStateService,
    private componentState: ComponentStateService,
    private snackbar: SnackbarService
  ) { }

  ngOnDestroy(): void {
    this.form.reset();
    this.componentState.reset();
    this.subscription$.next();
  }

  get hasItinerary$(): Observable<boolean> {
    return this.store.select(ItineraryState.basicInfo).pipe(
      map(basicInfo => !!basicInfo?.hasItinerary)
    );
  }

  get unsavedBlog$(): Observable<boolean> {
    return this.store.select(ItineraryState.basicInfo).pipe(
      map(response => !response?.id)
    );
  }

  get unsavedBlog(): boolean {
    return !this.store.selectSnapshot(ItineraryState.basicInfo)?.id;
  }

  componentObserver(): void {

    this.basicInfoChanges((response: Partial<BasicInfoData> | null) => {
      syncPageTitle(this.location, response?.pageTitle);
    });

    this.basicInfoChanges((response: Partial<BasicInfoData> | null) => {
      if (this.router.url === `/${currentLang()}/compose/new-story`) return;
      this.componentState.patch({ processing: !response });
      this.componentState.patch({ bannerProcessing: !response });
    });

    this.componentState.processing$.subscribe((state: boolean) => {
      this.form.get('storyTitle')?.[state ? 'disable' : 'enable']()
      this.form.get('storyLocation')?.[state ? 'disable' : 'enable']()
    });

    this.form.get('storyTitle')?.valueChanges.subscribe((value: string) => {
      this.navbarControl.navbarTitleState$$.next(value);
    });

    this.componentState.value$.pipe(
      map((componentState: ComponentState) => componentState!.storyTitle!),
      distinctUntilChanged(),
      tap((storyTitle: string) => {
        this.form.patchValue({ storyTitle });
        this.navbarControl.navbarTitleState$$.next(storyTitle);
      }),
      takeUntil(this.subscription$)
    ).subscribe();

    this.owner$.pipe(map(e => !e), filter(Boolean)).subscribe(() => {
      if (this.location.path().includes(`/compose/new-story`)) return;
      getWindowRef()[fromComposePageNotOwner] = '1';
      const pageTitle = this.location.path().replace(`/${currentLang()}/compose/travel-story/`, '');
      this.router.navigate([ currentLang(), 'travel-story', pageTitle ]);
    });
  }

  private basicInfoChanges(callback: (response: Partial<BasicInfoData> | null) => void): void {
    this.basicInfo$.pipe(
      takeUntil(this.subscription$)
    ).subscribe(callback.bind(this));
  }

  languageSelection(): void {
    this.snackbar.open({ message: 'You have English as your preferred language.' });
  }
  
  private get owner$(): Observable<boolean> {
    return combineLatest([
      this.store.select(UserState.profileId).pipe(filter(Boolean)),
      this.store.select(ItineraryState.basicInfo).pipe(filter(Boolean)),
    ]).pipe(
      map(([ profileId, basicInfo ]) => 
        basicInfo?.author?.authorTitle === profileId
        || clientVID() === basicInfo?.author?.authorProfId
      ),
      takeUntil(this.subscription$)
    );
  }

  dispatchAddItineraryDay(dayLocMap?: SearchLocationData): Observable<unknown> {
    return this.store.dispatch(new ItineraryAction.AddItineraryDay({
      dayIndex: 1,
      id: this.store.selectSnapshot(ItineraryState.basicInfo)?.id || '',
      locMap: dayLocMap ? {
        id: dayLocMap?.locId || '',
        location: dayLocMap?.name || '',
        country: dayLocMap?.country || '',
        formattedAddr: dayLocMap?.location || '',
      } : null,
      param: 'blog',
      vId: clientVID()
    }))
  }

  dispatchRemoveItinerary(): Observable<unknown> {
    return this.store.dispatch([
      new ItineraryAction.UpdateItineraryActivity({
        id: this.store.selectSnapshot(ItineraryState.basicInfo)?.id || '',
        action: 'delete-itinerary',
        lang: currentLang(),
        param: 'blog'
      }),
      new ItineraryAction.PartialResetItineraryState([
        'actDateMap'
      ])
    ])
  }

}
