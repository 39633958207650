import { OnInit, Directive, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Select, Store } from '@ngxs/store';
import { Observable, firstValueFrom, takeUntil, tap } from 'rxjs';

import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { preventRemoveKey, ViewNavbarAction } from '@hiptraveler/features/itinerary';
import { AppListenerService, NavbarControlStateService, currentLang, getWindowRef } from '@hiptraveler/common';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { CommonService } from './common.service';

@Directive({
  selector: '[navbarActionListener]'
})
export class NavbarActionListenerDirective extends ViewNavbarAction implements OnInit, OnDestroy {

  @Select(ItineraryState.actDate) actDate$: Observable<unknown>

  constructor(
    private router: Router,
    private location: Location,
    private common: CommonService,
    a: Store, b: AppListenerService, c: NavbarControlStateService, d: AuthDialogActionService
  ) {
    super(a, b, c, d);
  }

  ngOnInit(): void {
    this.navbarActionListener();
    this.navbarActionStateObserver();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
    this.resetAuthHandle();
  }

  /**
   * Save this trip navbar action button endpoint request function
   */
  async saveItineraryTrip(): Promise<void> {

    if (this.authenticationProcess()) return;

    try {
      this.common.processing$$.next(true);
      const id = this.store.selectSnapshot(ItineraryState.basicInfo)!.id || '';
      await firstValueFrom(this.store.dispatch(new ItineraryAction.AddItineraryBoard(id)));
    } finally {
      this.common.processing$$.next(false);
      this.navbarControl.navbarToolbarActionComplete$$.next();
    }
  }

  /**
   * Navbar actions listener:
   * Listens to action button clicks in the navbar.
   */
  private navbarActionListener(): void {

    this.observeCreatePDF();

    this.appListener.globalSignalListener('saveThisTrip').pipe(
      tap(this.saveItineraryTrip.bind(this)),
      takeUntil(this.subscription$)
    ).subscribe();

    this.appListener.globalSignalListener('travelStoryEdit').pipe(
      tap(() => {
        getWindowRef()[preventRemoveKey] = '1';
        const pageTitle = this.location.path().replace(`/${currentLang()}/itinerary/`, '');
        this.router.navigate([ currentLang(), 'compose', 'itinerary', pageTitle ]);
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

}
