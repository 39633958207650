
export type AllowScroll = 'allow' | 'allow-up' | 'allow-down' | 'disable';

export type ScrollDirection = 'up' | 'down';

export type ScreenState = Record<ScreenView, boolean>;

export type CarouselFilterType = 'location' | 'experience' | 'travelStyle' | 'activity';

export type CarouselScreenValue = Extract<ScreenView, ScreenView.travelCarousel | ScreenView.experienceCarousel | ScreenView.activitiesCarousel | ScreenView.locationCarousel>;

export type FormCarouselData = Record<CarouselScreenValue, string[]>;

export type SummaryItems = Record<string, any>[] | null;

export interface FormDateRange {
  start: Date | null;
  end: Date | null;
}

export interface FormClientInfo {
  adults: number;
  children: number;
  infants: number;
}

export interface FormValue {
  dateRange: FormDateRange;
  carouselData: FormCarouselData
  clientInfo: FormClientInfo;
}

export type ScreenViewValue = Record<ScreenView, number>;

export enum ScreenView {

  // Mobile specific screens
  welcomeAndMainSelection = 'screen1',
  carouselGrid = 'screen2',

  // Desktop screens
  welcome = 'screen1',
  mainSelection = 'screen2',
  experienceCarousel = 'screen3',
  travelCarousel = 'screen4',
  activitiesCarousel = 'screen5',
  locationCarousel = 'screen6',
  calendar = 'screen7',
  clientInfo = 'screen8',
  summary = 'screen9',

  // backup
  screen10 = 'screen10',
  screen11 = 'screen11',
  screen12 = 'screen12'
}
