import { Injectable, Provider } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';

const ignoredEndpoints: string[] = [
  'cloudfront.net/assets/clients/locations/',
  'cloudfront.net/assets/clients/filters/',
  'cloudfront.net/site/main/static/',
  '/api/auth/invalidate-api-cookie',
]

@Injectable()
export class _CookieInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (ignoredEndpoints.some(e => request.url.includes(e))) {
      return next.handle(request.clone());
    }

    return next.handle(request.clone({ withCredentials: true }));
  }

}

export const CookieInterceptor: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: _CookieInterceptor,
  multi: true,
}
