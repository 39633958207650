import { AfterViewInit, Component, HostListener, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { filter } from 'rxjs';

import { expFinderPanelAction } from '@hiptraveler/features/experience-finder';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { ExperienceFinderAiService } from './experience-finder-ai.service';
import { promiseDelay, SearchLocationService } from '@hiptraveler/common';

@Component({
  selector: 'experience-finder-ai',
  templateUrl: './experience-finder-ai.component.html',
  styleUrls: [
    './experience-finder-ai.component.scss',
    './experience-finder-ai-desktop.scss',
    './experience-finder-ai-mobile.scss'
  ],
  host: { class: 'experience-finder-ai-element' },
  viewProviders: [ ExperienceFinderAiService ],
  encapsulation: ViewEncapsulation.None
})
export class ExperienceFinderComponent implements OnInit, AfterViewInit {

  @ViewChild('experienceFinder', { read: ViewContainerRef }) experienceFinderRef: ViewContainerRef;
  @ViewChild('experienceFinderMobile', { read: ViewContainerRef }) experienceFinderMobile: ViewContainerRef;

  constructor(
    private searchLocation: SearchLocationService,
    public stateService: ExperienceFinderStateService,
    public service: ExperienceFinderAiService
  ) { }

  ngOnInit(): void {
    this.service.observeExpFinderViewChanges();
    this.service.observeExpFinderBubblePosition();
    this.service.observeExpFinderHostParentWidth();
  }

  @HostListener('document:keydown.escape')
  onEscapeKey(): void {
    this.service.experienceFinderPanelDisplay(false);
  }

  async ngAfterViewInit(): Promise<void> {

    await promiseDelay(100);

    if (!this.experienceFinderRef) {
      this.ngAfterViewInit();
      return;
    }

    this.service.experienceFinderRef = this.experienceFinderRef;
    this.service.panelAction = expFinderPanelAction();
    
    if (!this.stateService.isMobile && this.searchLocation.rootSearchRoute) {
      this.service.experienceFinderPanelDisplay(true);
    }
    
    this.stateService.isMobile$.subscribe((state: boolean) => {
      this.service.experienceFinderRef = state ? this.experienceFinderMobile : this.experienceFinderRef;
    });
    
    this.stateService.displayState$.subscribe((state: boolean) => {
      this.service.experienceFinderPanelDisplay(state);
    });
    
    this.stateService.overlayPositionState$.pipe(filter(Boolean)).subscribe(() => {
      this.stateService.overlayState$$.value
      && this.service.experienceFinderPanelDisplay(false);
    });
  }

}
