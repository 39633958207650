import { Directive, ElementRef, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, delay, filter, take, takeUntil, tap } from 'rxjs';

import { currentLang } from '@hiptraveler/common';
import { BrandState } from '@hiptraveler/data-access/brand';

@Directive({
  selector: '[optionHighlight]'
})
export class OptionHighlightDirective implements OnDestroy, AfterViewInit {

  subscription$ = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    private element: ElementRef<HTMLDivElement>,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store
  ) { }
  
  ngAfterViewInit(): void {
    this.observeActiveElements();
    this.routeNavigationEndObserver();
    this.fragmentChangesObserver();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  routeNavigationEndObserver(): void {

    this.highlightRootPage();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      delay(0),
      tap(this.observeActiveElements.bind(this)),
      // takeUntil(this.subscription$)
    ).subscribe();
  }

  highlightRootPage(): void {

    this.observeActiveElements({ url: this.router.url });

    if (this.router.url === `/${currentLang()}`) {
      const element = this.element.nativeElement.children.item(0);
      element?.classList.add('active');
    }
  }

  /* Router attribute observers */

  observeActiveElements(event?: any): void {
    const fragment = !!this.route.snapshot.fragment;
    const component = this.element.nativeElement.className.includes('component');
    const valid = !fragment && !component;
    valid && this.store.select(BrandState.brandCampaign).pipe(delay(100),take(1)).subscribe(() => {
      [ ...Array.from(this.element.nativeElement.children) as HTMLDivElement[] ].forEach((element) => {
        if (!!event?.url && event?.url === `/${currentLang()}`) {
          event.url = `/${currentLang()}/search`;
        }
        const url = event?.url?.replace(`/${currentLang()}/`, '');
        const validate = url?.includes(element.getAttribute('data-url'));
        this.renderer.removeClass(element, 'active');
        validate && this.renderer.addClass(element, 'active');
      })
    });
  }

  /* Fragment observers */

  fragmentChangesObserver(): void {
    this.route.fragment.pipe(
      delay(0),
      tap(this.addRemoveActiveClassByFragment.bind(this)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  addRemoveActiveClassByFragment(fragment: string | null): void {

    if (!this.element.nativeElement.classList.contains('scroll-to-view')) return;

    const children = Array.from(this.element.nativeElement.children);
    children.forEach((element) => {
      this.renderer.removeClass(element, 'active');
    });

    if (!fragment) {
      setTimeout(() => this.renderer.addClass(children[0], 'active'), 100);
      return;
    }

    const activeElement = children.find(e => e.getAttribute('fragment') === fragment);
    activeElement && this.renderer.addClass(activeElement, 'active')
  }

}
