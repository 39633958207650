import { Injectable } from '@angular/core';
import { BehaviorSubject, tap, takeUntil, distinctUntilChanged, skip, map } from 'rxjs';

import { ChatCompletionsService } from '@hiptraveler/core/openai';
import { EFAStateServiceService, FormClientInfo, SummaryItems } from '../../shared';
import { ClientInfo } from './client-info.model';
import { CLIENT_DATA, convertClientInfo } from './client-action/client-action';

@Injectable()
export class ClientInfoStateService {

  clientData$$ = new BehaviorSubject<ClientInfo[]>(CLIENT_DATA);
  clientData$ = this.clientData$$.asObservable();
  
  constructor(
    private completions: ChatCompletionsService,
    private stateService: EFAStateServiceService
  ) { }

  clientDataObserver(): void {
    this.clientData$.pipe(
      skip(1),
      tap((data: ClientInfo[]) => {
        const clientInfo: FormClientInfo = {
          adults: data[0].count,
          children: data[1].count,
          infants: data[2].count
        };
        this.stateService.patchFormValue({ clientInfo });
        this.stateService.summaryItems$$.next(this.formatClientInfo(clientInfo));
      }),
      takeUntil(this.stateService.subscription$)
    ).subscribe();
  }

  formValueObserver(): void {
    this.stateService.formValue$.pipe(
      map(e => e.clientInfo),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      takeUntil(this.stateService.subscription$)
    ).subscribe((clientInfo: FormClientInfo) => {
      this.clientData$$.next(convertClientInfo(clientInfo));
    });
  }

  private formatClientInfo(clientInfo: FormClientInfo): SummaryItems {
    const summaryItems = this.stateService.summaryItems$$.value?.filter((e: any) => e?.source !== 'clientInfo') || [];
    return [
      { source: 'clientInfo', output: `${clientInfo.adults} Adults`, value: clientInfo.adults },
      { source: 'clientInfo', output: `${clientInfo.children} Children`, value: clientInfo.children },
      { source: 'clientInfo', output: `${clientInfo.infants} Infants`, value: clientInfo.infants }
    ]
      .filter(item => item.value !== 0)
      .map(({ value, ...rest }) => rest)
      .concat([ ...summaryItems as any ]);
  }

}
