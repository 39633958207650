import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarouselGridComponent } from './carousel-grid.component';
import { PoiSelectCarouselModule } from '../../components/poi-select-carousel';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  PoiSelectCarouselModule
];

const COMPONENTS = [
  CarouselGridComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    CarouselGridComponent
  ]
})
export class CarouselGridModule { }
