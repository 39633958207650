import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { ActivityDateData } from '@hiptraveler/data-access/api';
import { SearchState } from '@hiptraveler/data-access/search';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { PromptDialogActionService, PromptDialogStateService } from '@hiptraveler/dialogs/prompt-dialog';
import { SEARCH_RESULT_ID } from '@hiptraveler/dialogs/search-result-dialog';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { ItineraryActionPanelDataAccessService } from './itinerary-action-panel-data-access.service';
import { ItineraryActionService } from './../itinerary-action.service';
import { SnackbarService } from '@hiptraveler/snackbar';
import * as Common from '@hiptraveler/common';
import * as Helper from './itinerary-action-panel.helper';
import * as DialogCase from './add-item-dialog-cases';

@Injectable()
export class ItineraryActionPanelService {

  activityId: string = '';

  constructor(
    private dialog: MatDialog,
    private store: Store,
    private appListener: Common.AppListenerService,
    private promptDialog: PromptDialogActionService,
    private promptDialogState: PromptDialogStateService,
    private efStateService: ExperienceFinderStateService,
    private dataAccess: ItineraryActionPanelDataAccessService,
    private searchPageControl: Common.SearchPageControlStateService,
    private stateService: ItineraryActionService,
    private snackbar: SnackbarService
  ) { }

  /**
   * Opens the My Trips view.
   */
  openMyTrips(): void {
    this.dialog.getDialogById(SEARCH_RESULT_ID)?.close();
    this.efStateService.overlayState$$.next(true);
    // this.efStateService.dialogView$$.next('main-menu');
    // this.efStateService.mainMenuView$$.next('plan_a_trip');
    this.appListener.cardMenuPanelState$$.next('-');
    location.pathname === `/${Common.currentLang()}`
      // && setTimeout(() => this.efStateService.dialogView$$.next('main-menu'), 200);
  }
  
  /**
   * Opens the Create New Trip dialog.
   */
  openCreateNewTrip(): void {
    this.dialog.getDialogById(SEARCH_RESULT_ID)?.close();
    sessionStorage.setItem('fcant', '1');
    this.efStateService.overlayState$$.next(true);
    // setTimeout(() => this.efStateService.dialogView$$.next('plan-trip'), 300);
    this.appListener.cardMenuPanelState$$.next('-');
  }

  /**
   * Adds an activity to all days of the itinerary.
   * @param activityId The ID of the activity to add.
   */
  async addToAllDays(activityId: string): Promise<void> {
    this.dataAccess.addAllHotelAction(activityId);
  }

  /**
   * Updates an activity in the itinerary.
   * @param activity The updated activity data.
   */
  private async updateItineraryActivity(activityId: string | undefined, activity: ActivityDateData): Promise<void> {

    const value = {
      dto: this.searchPageControl.islandHopPayload$$.value!,
      response: this.searchPageControl.islandHopResponse$$.value!
    };

    if (value.dto && value.response && activityId) {
      await firstValueFrom(this.store.dispatch(new ItineraryAction.UpdateItineraryActivityByResponse(value)))
      this.dataAccess.processCardByIdAndState(activityId, 'exit');
      
    } else {
      this.dataAccess.addItemAction(activityId, activity);
    }
  }

  /**
   * Replaces an activity in the itinerary.
   * @param data The replacement activity data.
   */
  async replaceItineraryActivity(data: Common.ReplaceItineraryActivity): Promise<void> {
    this.dataAccess.removeOldItemAction(this.activityId, data);
  }

  /**
   * Adds an activity to a specific date in the itinerary. Handles various validations and edge cases.
   * 
   * @param activityDay The activity data to add.
   * @param tourData The tour data associated with the activity (optional).
   */
  async addToDate(activityDay: ActivityDateData): Promise<void> {

    this.appListener.cardMenuPanelState$$.next('-');

    const actDates = [
      ...this.store.selectSnapshot(ItineraryState.actDate)?.flatMap(e => e.ImgArray) || [],
      ...this.store.selectSnapshot(ItineraryState.actDate)?.flatMap(e => e.HotelArray) || [],
    ].find(e => e?.id === this.activityId);

    const activityDayStore = this.searchPageControl.activityDate$$.value;
    const selectedSearchResult: any = this.store.selectSnapshot(SearchState.searchResult(this.activityId)) || actDates;

    selectedSearchResult['duration'] = selectedSearchResult?.duration || 0;

    if (Helper.poiAlreadyExists(activityDay, selectedSearchResult)) {
      const param = { promptDialog: this.promptDialog, promptDialogState: this.promptDialogState, activity: activityDay };
      return DialogCase.itemAlreadyIncluded(param, () => this.stateService.openPreviousActionPanel());
    }

    if (Helper.isMultiDayTour(selectedSearchResult) && Helper.hasTours(activityDay)) {
      const param = { promptDialog: this.promptDialog, promptDialogState: this.promptDialogState, activity: activityDay };
      return DialogCase.addingMultiDayTour(param, () => this.stateService.openPreviousActionPanel());
    }

    if (activityDay.hasMultiDayTour) { // Case #4, add-to-trip.pdf
      const param = { promptDialog: this.promptDialog, promptDialogState: this.promptDialogState, activity: activityDay };
      return DialogCase.multiDayTourAdded(param, () => this.stateService.openPreviousActionPanel());
    }

    if (Helper.exceedsOneDayDuration(activityDay, selectedSearchResult) && Helper.hasTours(activityDay)) {
      const param = { promptDialog: this.promptDialog, promptDialogState: this.promptDialogState, activity: activityDay };
      return DialogCase.dayOverflowError(param, async (activity: ActivityDateData) => {
        this.updateItineraryActivity(selectedSearchResult?.id, activity); // Case #1 & #2, add-to-trip.pdf
      },() => this.stateService.openPreviousActionPanel());
    }

    if (Helper.insufficientDaysAvailable(activityDayStore, activityDay, selectedSearchResult)) {
      const param = { promptDialog: this.promptDialog, promptDialogState: this.promptDialogState, activity: activityDay };
      return DialogCase.addMultiDayTour(param, async (activity: ActivityDateData) => {
        this.updateItineraryActivity(selectedSearchResult?.id, activity); // Case #3, add-to-trip.pdf
      }, () => this.stateService.openPreviousActionPanel());
    }

    try {

      const islandHoppingResult = await this.islandHoppingFeatureProcess(activityDay, selectedSearchResult);
      if (islandHoppingResult) return;
  
      this.updateItineraryActivity(selectedSearchResult?.id, activityDay);

    } catch (response: any) {

      this.dataAccess.processCardByIdAndState(selectedSearchResult?.id, 'exit');

      if (response?.error !== 'incomplete_data_location_message') return;
      const param = { promptDialog: this.promptDialog, promptDialogState: this.promptDialogState, activity: activityDay };
      DialogCase.incompleteLocationInformation(param);
    }
  }

  /**
   * Island hopping dialog and condition UX
   * 
   * @param activityDay The activity data to add.
   * @param selectedSearchResult Selected search result POI card object
   */
  private async islandHoppingFeatureProcess(activityDay: ActivityDateData, selectedSearchResult: any): Promise<boolean> {
    
    const activityDayStore = this.searchPageControl.activityDate$$.value;

    if (Common.subdomain() === Common.BAHAMAS_BRAND_NAME && activityDayStore?.itineraryId) {
      this.dataAccess.processCardByIdAndState(selectedSearchResult?.id, 'enter');
      await this.dataAccess.requestIslandData(selectedSearchResult?.id, activityDay);
    }

    const islandHopResponse = this.searchPageControl.islandHopResponse$$.value;

    if (Common.subdomain() === Common.BAHAMAS_BRAND_NAME && !islandHopResponse) {
      this.snackbar.open({ message: 'Please try again.', duration: 5000 });
      this.dataAccess.processCardByIdAndState(selectedSearchResult?.id, 'exit');
      return true;
    }

    if (Common.subdomain() === Common.BAHAMAS_BRAND_NAME && Helper.isIslandHopping(activityDay, islandHopResponse, selectedSearchResult)) {
      const selectedActivityId = selectedSearchResult?.id;
      const param = { promptDialog: this.promptDialog, promptDialogState: this.promptDialogState, activity: activityDay };
      DialogCase.requireIslandHopping(param, async (activity: ActivityDateData) => {
        this.promptDialog.close(true);
        this.updateItineraryActivity(selectedActivityId, activity); // Case #5, add-to-trip.pdf
      }, () => {    
        this.stateService.openPreviousActionPanel();
      }, () => {
        this.searchPageControl.islandHopPayload$$.next(null);
        this.searchPageControl.islandHopResponse$$.next(null);
        this.searchPageControl.requestCancellation$$.next();
        selectedActivityId && this.dataAccess.removeSelectedItinerary(selectedActivityId, activityDay).subscribe();
        this.dataAccess.processCardByIdAndState(selectedSearchResult?.id, 'exit');
      });
      return true;
    }

    return false;
  }

}
