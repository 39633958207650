import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ProfileSelectionComponent } from './profile-selection.component';
import { UserProfileSvgComponent } from './user-profile-svg.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatMenuModule,
  MatDividerModule,
  MatProgressSpinnerModule,
  TranslateModule,
  LazyLoadImageModule
];

const COMPONENTS = [
  ProfileSelectionComponent,
  UserProfileSvgComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ProfileSelectionComponent
  ]
})
export class ProfileSelectionModule { }
