import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

import { DialogDataType, MoreContentDetailsDialogActionService } from './more-content-details';

@Directive({
  selector: '[moreContentAction]'
})
export class MoreContentActionDirective implements AfterViewInit {

  @Input() label: string;
  @Input() display: string;
  @Input() type: DialogDataType = null;

  constructor(
    private element: ElementRef<HTMLElement>,
    private dialog: MoreContentDetailsDialogActionService
  ) { }

  ngAfterViewInit(): void {

    const regex = /\b\w+\.\.\.\s*$/;
    const content = this.element.nativeElement.previousElementSibling;

    if (!regex.test(content?.textContent || '')) {
      content?.classList.remove('more-content');
      this.element.nativeElement.style.display = 'none';
    }
  }

  @HostListener('click')
  hostClicked(): void {
    this.dialog.open({ label: this.label, display: this.display, type: this.type });
  }

}
