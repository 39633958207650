import { carouselPrompt, calendarPrompts, clientInfoPrompts } from ".";

export const mainSelectionPrompts = (keyCodes: string[]): string[] => [
  `--- General Prompt ---

    The user’s inputs relate only to trip planning. If the conversation veers off topic, kindly remind the user that this API only assists with trip or travel planning.
  `,
  `--- Screen 3,4,5,6: Carousel View Prompt ---
    ${carouselPrompt(keyCodes)}
    - The user’s inputs relate only to trip planning. If the conversation veers off topic, kindly remind the user that this API only assists with trip or travel planning.
    - screen_345_carousel_view_data Response Format: (example) ["list_of_matching_keyCodes"]
  `,
  `--- Screen 7: Calendar Prompt ---
    
    ${calendarPrompts}
  `,
  `--- Screen 8: Client Info Prompt ---

    ${clientInfoPrompts}
  `,
  `--- Response Format Prompt ---

    Compile all the response from screen_3456_carousel_view_data, screen_7_calendar_data and screen_8_client_info_data and response in this format

    {
      "carouselData": screen_3456_carousel_view_data,
      "dateRange": screen_7_calendar_data,
      "clientInfo": screen_8_client_info_data
    }
  `
];
