import { Injectable } from '@angular/core';
import { ChatCompletion } from 'openai/resources';
import { firstValueFrom, map } from 'rxjs';

import { SearchApiService } from '@hiptraveler/data-access/api';
import { ChatCompletionsService } from '@hiptraveler/core/openai';
import { EFAStateServiceService, CarouselFilterType, carouselScreenViewPrompts, inputLocationPrompt, parseStateLocation, responseToValue, CarouselScreenValue, ScreenView, CarouselService } from '../../shared';
import { DEFAULT_BRAND_NAME, subdomain } from '@hiptraveler/common';

@Injectable()
export class ScreenCarouselService {

  constructor(
    private searchApi: SearchApiService,
    private completions: ChatCompletionsService,
    private stateService: EFAStateServiceService
  ) { }

  observe(screen: CarouselScreenValue, filter: CarouselFilterType, service: CarouselService): void {
    this.stateService.getInputMessageByScreen(screen).subscribe(async (inputMessage: string) => {
      try {
        this.stateService.inputPending$$.next(true);
        this.stateService.pauseBrandInfoUXState$$.next(false);
        const travelStyleLabels = service.getDataByFilter(filter, 'snapshot').map((e: any) => e.code || '').filter(Boolean);
        const chatCompletion = await this.completions.sendMessage(
          inputMessage, carouselScreenViewPrompts(travelStyleLabels)
        );
        const response = await this.getResponse(chatCompletion, inputMessage);
        const carouselData = this.stateService.formValue.carouselData;
        const screenValue = screen === ScreenView.locationCarousel ? response : [ ...carouselData[screen], ...response ];
        this.stateService.patchFormValue({ carouselData: { ...carouselData, [screen]: screenValue } });
      } catch (e) { }
      finally {
        this.stateService.inputPending$$.next(false);
        this.stateService.pauseBrandInfoUXState$$.next(true);
      }
    });
  }

  private async getResponse(chatCompletion: ChatCompletion, inputMessage: string): Promise<string[]> {

    let response = await JSON.parse(chatCompletion.choices[0].message.content || '[]') as string[];

    if (!response?.length && subdomain() === DEFAULT_BRAND_NAME) {
      const chatCompletion = await this.completions.sendMessage(inputMessage, inputLocationPrompt);
      const locationResponse = chatCompletion.choices[0].message.content || '';
      const locationData = await firstValueFrom(this.searchApi.getLocationDataByQuery({ q: locationResponse, format: 'basic' }).pipe(
        map(e => responseToValue(e))
      ));

      if (!locationData) {
        console.error('[CarouselService]: No location data returned from the backend.');
        return [];
      }

      const experience = parseStateLocation(locationData);
      this.stateService.appendLocation(experience);
      response = [ experience?.code || '' ];
    }

    return response;
  }

}
