<div class="merged-shape mat-elevation-z7" [ngClass]="{ mobile: stateService.isMobile$ | async}">

  <div class="summary">
    <ng-container *ngFor="let summaryItem of hostService.summaryTags$ | async;let i = index">
      <div class="summary--item">
    
        <p class="summary--item-text">{{ summaryItem['output'] }}</p>
    
        <div class="summary--item-action" matRipple (click)="stateService.removeSummaryItem(i)">
          <mat-icon>close</mat-icon>
        </div>
    
      </div>
    </ng-container>
  </div>

</div>
