import { Component, AfterViewInit, ViewChild, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

import { ChatCompletionsService } from '@hiptraveler/core/openai';
import { EFAStateServiceService, FormDateRange } from '../../shared';
import { ScreenCalendarService } from '../../features';
import { CalendarDateRangeProviders, CalendarService, formDateRange } from './calendar.service';
import { promiseDelay } from '@hiptraveler/common';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  host: { class: 'experience-finder-ai--host-calendar experience-finder-ai--host-element' },
  viewProviders: [ ChatCompletionsService, CalendarService, ScreenCalendarService, CalendarDateRangeProviders ],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements AfterViewInit {

  @ViewChild('calendar1') calendar1: MatCalendar<moment.Moment>;
  @ViewChild('calendar2') calendar2: MatCalendar<moment.Moment>;

  minDate = new Date();
  selectedDateRange$$ = new BehaviorSubject<DateRange<moment.Moment> | null>(null);
  disablePrevButton: boolean;

  constructor(
    private cdRef: ChangeDetectorRef,
    private completions: ChatCompletionsService,
    private stateService: EFAStateServiceService,
    public chatAI: ScreenCalendarService,
    public service: CalendarService
  ) { }

  ngAfterViewInit(): void {
    this.completions.authInitialize();
    this.chatAI.observe();
    this.initializeCalendarView();
    this.observeFormChanges();
  }

  async initializeCalendarView(): Promise<void> {

    await promiseDelay(0)

    const currentDate = moment();
    this.calendar2.activeDate = currentDate.clone().add(1, 'month').startOf('month');
    this.disablePrevButton = this.calendar1.activeDate.month() === currentDate.month();
  }

  observeFormChanges(): void {
    this.service.formCleared$.subscribe((() => {
      this.service.updateSummaryItems(null);
      this.selectedDateRange$$.next(null);
      this.cdRef.detectChanges();
    }));
    this.service.formValueObserver().subscribe(((dateRange: FormDateRange) => {
      this.service.updateSummaryItems(dateRange);
      this.selectedDateRange$$.next(formDateRange(dateRange));
      this.cdRef.detectChanges();
    }));
  }
  
  onSelectedChange(date: moment.Moment): void {

    const { start, end } = this.selectedDateRange$$.value as any || { start: null, end: null };

    if (start && !end && start?.unix() < date?.unix()) {
      const selectedDateRange = new DateRange(start, date);
      const dateRange = { start: selectedDateRange.start._d, end: selectedDateRange.end._d };
      this.selectedDateRange$$.next(selectedDateRange);
      this.stateService.patchFormValue({ dateRange });
    } else {
      this.selectedDateRange$$.next(new DateRange(date, null));
    }
  }

  updateCalendarDisplay(fn: 'add' | 'subtract'): void {
    const calendar1_activeDate = moment(this.calendar1.activeDate)
    const calendar2_activeDate = moment(this.calendar2.activeDate)
    this.calendar1.activeDate = calendar1_activeDate.clone()[fn](1, 'month').startOf('month');
    this.calendar2.activeDate = calendar2_activeDate.clone()[fn](1, 'month').startOf('month');
    this.disablePrevButton = this.calendar1.activeDate.month() === moment().month();
  }

}
