import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { flatMap, map } from 'lodash';

import { ASSETS } from '@hiptraveler/common';

type SvgIcon = Record<string, string>;
type SvgCollection = Record<string, SvgIcon>;

@Injectable({
  providedIn: 'root'
})
export class ImageSvgExternalService {

  private svgCollection$$ = new BehaviorSubject<SvgCollection | null>(null);
  private svgCollectionCache$: Observable<SvgCollection>;

  constructor(
    private http: HttpClient,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) { }

  get imageCollection$(): Observable<any> {
    return this.http.get(ASSETS.svg.collection);
  }

  get svgCollection(): SvgCollection | null {
    return this.svgCollection$$.value;
  }

  get svgCollection$(): Observable<SvgCollection> {
    if (!this.svgCollectionCache$) {
      this.svgCollectionCache$ = this.http.get<SvgCollection>(ASSETS.svg.collection).pipe(
        shareReplay(1)
      );
    }
    return this.svgCollectionCache$;
  }

  registerIcons(callback: () => void): void {
    this.svgCollection$.subscribe((response: SvgCollection) => {

      this.svgCollection$$.next(response);

      const collectionKeys = flatMap(response, (record, field) => 
        map(record, (_, recordKey) => `${field}.${recordKey}`)
      );

      collectionKeys.forEach(keys => {
        const [ parent, child ] = keys.split('.')
        this.iconRegistry.addSvgIconLiteral(keys, this.sanitizer.bypassSecurityTrustHtml(response[parent][child]));
      })

      callback();
    });
  }

}
