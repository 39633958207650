import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { ProfileSelectionModule } from '@hiptraveler/features/profile-selection';
import { LoaderScreenModule } from './../components/loader-screen/loader-screen.module';

import { ExperienceFinderAiComponent } from './experience-finder-ai.component';
import { HostDirectives } from '../features';

const MODULES = [
  CommonModule,
  MatButtonModule
];

const LIBRARIES = [
  ProfileSelectionModule,
  LoaderScreenModule,
  HostDirectives
];

const COMPONENTS = [
  ExperienceFinderAiComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ]
})
export class ExperienceFinderAiModule { }
