import { StateContext, Store } from '@ngxs/store';
import { pick, uniqBy } from 'lodash';

import * as API from '@hiptraveler/data-access/api';
import { UserAction, UserState } from '@hiptraveler/data-access/user';
import { ItineraryState, ItineraryStateModel } from '..';
import { getWindowRef, globalStateManagementStoreKey } from '@hiptraveler/common';

interface Param {
  ctx: StateContext<ItineraryStateModel>;
  basicInfoResponse?: Partial<API.BasicInfoData>;
  param: API.ItineraryParam;
  data: Partial<API.BasicInfoData>
}

export function updateItineraryState(value: Param): void {
  const updater = value.param === 'blog' ? updateBlog : updateItinerary;
  value.ctx.patchState({ basicInfo: updater(value) });
}

function updateBlog({ ctx, data, basicInfoResponse }: Param): Partial<API.BasicInfoData> {

  const state = ctx.getState()?.basicInfo || {};

  basicInfoResponse!.author ??= state?.author;
  basicInfoResponse!.pubDateString ??= state?.pubDateString;

  const coverImage = data?.coverImage || basicInfoResponse?.coverImage || state?.coverImage;
  const response = pick(basicInfoResponse, [ 'content', 'snippet', 'title', 'pageTitle', 'author', 'pubDateString', 'id' ]);

  const result = { ...state, ...response, coverImage, pageUrl: parsePageUrl(basicInfoResponse) } as Partial<API.BasicInfoData>;

  modifyResultInUserBlogs(result);

  return result;
}

function updateItinerary({ ctx, basicInfoResponse }: Param): Partial<API.BasicInfoData> {
  const state = (ctx.getState()?.basicInfo || {});

  basicInfoResponse && modifyResultInUserItineraries(basicInfoResponse);

  return { ...state, ...basicInfoResponse };
}

// Helpers

function parsePageUrl(basicInfoResponse?: Partial<API.BasicInfoData>): string {
  return basicInfoResponse?.pageUrl?.replace('http://localhost:8080', 'http://localhost:4200') || '';
}

function modifyResultInUserItineraries(basicInfo: Partial<API.BasicInfoData>): void {

  const store = getWindowRef()?.[globalStateManagementStoreKey] as Store;

  let itineraries = store.selectSnapshot(UserState.itineraries) || [];
  let actDateArr = store.selectSnapshot(ItineraryState.actDateArr) || [];

  if (itineraries.map(e => e.id).includes(basicInfo?.id || '')) {
    
    itineraries = itineraries.map((itinerary: API.UserItineraryData) => {
      if (itinerary?.id === basicInfo?.id) {
        return {
          ...itinerary,
          pageTitle: basicInfo.pageTitle || '',
          title: basicInfo.title || '',
          datePublished: basicInfo.datePublished || '',
          itiDays: actDateArr.map(e => ({ day: e?.day || 1, dateString: e?.dateString || '' }))
        };
      }
      return itinerary;
    })
  } else {

    const itineraryResult: API.UserItineraryData = {
      id: basicInfo?.id || '',
      pageTitle: basicInfo?.pageTitle || '',
      title: basicInfo?.title || '',
      datePublished: basicInfo?.datePublished || '',
      campgnReference: basicInfo?.campgnReference || '',
      isAutoGenerated: basicInfo?.autoGenerated || false,
      isPublic: !basicInfo?.private,
      itiDays: actDateArr.map(e => ({ day: e?.day || 1, dateString: e?.dateString || '' })),
      isPackagedTour: false,
    };
 
    itineraries = [ itineraryResult, ...itineraries ];
  }

  store.dispatch(new UserAction.UpdateItineraryData(uniqBy(itineraries, 'id')));
}

function modifyResultInUserBlogs(basicInfo: Partial<API.BasicInfoData>): void {

  const store = getWindowRef()?.[globalStateManagementStoreKey] as Store;

  let blogs = store.selectSnapshot(UserState.blogs) || [];

  if (blogs.map(e => e.id).includes(basicInfo?.id || '')) {
    
    blogs = blogs.map((blog: API.UserBlogData) => {
      if (blog?.id === basicInfo?.id) {
        return {
          ...blog,
          pageTitle: basicInfo.pageTitle || '',
          title: basicInfo.title || '',
          datePublished: basicInfo.datePublished || ''
        };
      }
      return blog;
    })
  } else {

    const blogResult: API.UserBlogData = {
      id: basicInfo.id || '',
      pageTitle: basicInfo.pageTitle || '',
      title: basicInfo.title || '',
      datePublished: basicInfo.datePublished || ''
    };
 
    blogs = [ blogResult, ...blogs ];
  }

  store.dispatch(new UserAction.UpdateBlogData(uniqBy(blogs, 'id')));
}
