
export const CDN_DOMAIN = 'https://d36j46pvk6qi4f.cloudfront.net/site/main/static';

interface Asset {
  base64: {
    ambassadorHighlight: string;
    appCommon: string;
    experienceFinderAssets: string;
    experienceFinderDefault: string;
    footer: string;
    travelOptionsBackground: string;
    tripPlanner: string;
  },
  svg: {
    collection: string
  }
}

export const ASSETS: Asset = {
  base64: {
    ambassadorHighlight: `${CDN_DOMAIN}/ambassador-highlight-image.json`,
    appCommon: `${CDN_DOMAIN}/app-common-image.json`,
    experienceFinderAssets: `${CDN_DOMAIN}/experience-finder-asset-images.json`,
    experienceFinderDefault: `${CDN_DOMAIN}/experience-finder-default-image.json`,
    footer: `${CDN_DOMAIN}/footer-image.json`,
    travelOptionsBackground: `${CDN_DOMAIN}/travel-options-bg.json`,
    tripPlanner: `${CDN_DOMAIN}/trip-planner-image.json`
  },
  svg: {
    // collection: `${CDN_DOMAIN}/icon-svg-collection.json`
    collection: '/assets/icon-svg-collection.json'
  }
};
