const date = new Date();
const currentMonth = date.getMonth() + 1;
const currentYear = date.getFullYear();

export const calendarPrompts = [
  `
    The user will input travel dates in the format 'YYYY-MM-DD to YYYY-MM-DD'. Extract the start date and end date from their input. If they enter '${currentYear}-${currentMonth}-10 to ${currentYear}-${currentMonth}-15', output should be:
    Start Date: ${currentYear}-${currentMonth}-10
    End Date: ${currentYear}-${currentMonth}-15
  `,
  `
    If the user enters a duration like '7 days' without specific dates, assume today's date is YYYY-MM-DD and calculate the start and end dates. For example, if today is '${currentYear}-${currentMonth}-08' and the user types '7 days', output should be:
    Start Date: ${currentYear}-${currentMonth}-08
    End Date: ${currentYear}-${currentMonth}-15
  `,
  `
    If the user inputs a general time frame like 'next week' or 'in two weeks', interpret it as a date range starting from the following Monday. For example, if today is '${currentYear}-${currentMonth}-08' and the user types 'next week', the output should be:
    Start Date: ${currentYear}-${currentMonth}-12
    End Date: ${currentYear}-${currentMonth}-17
  `,
  `
    The user may input dates in various formats:
    - If they enter a specific date range like 'YYYY-MM-DD to YYYY-MM-DD', extract those dates directly.
    - If they enter a number of days, like '10 days', calculate the end date starting from today's date.
    - If they use general terms like 'next month' or 'in two weeks', interpret these and calculate the start and end dates accordingly.
    Output in the following format:
    Start Date: YYYY-MM-DD
    End Date: YYYY-MM-DD
  `,
  `
    Only generate a response in this format: { start: "YYYY-MM-DD", end: "YYYY-MM-DD" }. Ensure you only respond in this format and do not add any other text.
    Make sure to return this screen_6_calendar_data as a stringified JSON
    ex. "{ "start": "${currentYear}-${currentMonth}-08", "end": "${currentYear}-${currentMonth}-15" }"
  `
];


/*

  Supported user inputs

  // Specific Date Range Inputs

  2025-01-10 to 2025-01-15
  2025-02-10 to 2025-02-15
  2025-01-01 to 2025-01-10
  2025-02-01 to 2025-02-10
  2025-01-20 to 2025-01-25
  2025-02-20 to 2025-02-25

  // Duration in Days Inputs

  5 days
  10 days
  15 days from today
  3 days

  // General Time Frame Inputs

  next week
  in two weeks
  next month
  in three days
  starting from next Monday for a week
  this weekend
  mid-January
  end of the year

  // Flexible or Mixed Inputs

  I want to travel for 7 days starting January 8
  Any dates between January 15 and January 25
  From the start of January to mid-January
  Sometime next week for 5 days
  In a month for 10 days
  Starting January 1 for two weeks

*/