import { Experience, LocationData, SearchLocationDataResponse } from "@hiptraveler/data-access/api";
import { BrandStateModel } from "@hiptraveler/data-access/brand";

export function parseBrandState(state: BrandStateModel | null): BrandStateModel | null { // To do: for demo purposes only

  const experiences = state?.experiences || [];

  if (state?.experiences && state?.cLocations) {
    const locations = parseStateLocations(state.cLocations);
    return { ...state, experiences: [ ...experiences, ...locations ] }
  }

  return { ...state, experiences }
}

export function responseToValue(response: SearchLocationDataResponse): LocationData | null {

  if (response?.error) return null;

  return response.locationInfo;
}

export function parseStateLocation(location: LocationData): Partial<Experience> {
  return {
    id: location.id,
    name: location.formattedAddr,
    code: location.formattedAddr.toLowerCase().replace(/,/g, '').replace(/\s+/g, '_'),
    img: location?.coverImg?.imgThumbnail || '',
    category: 'location'
  };
}

export function parseStateLocations(locations?: LocationData[]): Partial<Experience>[] {
  return locations?.map((e) => ({
    id: e.id,
    name: e.formattedAddr,
    code: e.formattedAddr.toLowerCase().replace(/,/g, '').replace(/\s+/g, '_'),
    img: e?.coverImg?.imgThumbnail || '',
    category: 'location'
  } as any)) || [];
}
