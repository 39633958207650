import { Injectable } from '@angular/core';
import { delay, distinctUntilChanged, filter, map, Observable, switchMap, takeUntil } from 'rxjs';

import { EFAStateServiceService } from '../../shared/state-service.service';
import { FormDateRange } from '../../shared';

export * from './calendar';

@Injectable()
export class CalendarService {

  constructor(
    private stateService: EFAStateServiceService
  ) { }

  formValueObserver(): Observable<FormDateRange> {
    return this.stateService.screenViewValue$.pipe(
      switchMap(() => this.stateService.formValue$),
      map(e => JSON.stringify(e.dateRange)),
      distinctUntilChanged(),
      map(e => JSON.parse(e)),
      filter(value => value.start !== value.end),
      delay(100),
      takeUntil(this.stateService.subscription$)
    );
  }

  get formCleared$(): Observable<boolean> {
    return this.stateService.formValue$.pipe(
      map(e => {
        const { start, end } = e.dateRange;
        return !start && !end
      }),
      filter(Boolean)
    );
  }

  updateSummaryItems(form: FormDateRange | null): void {

    const summaryItems = this.stateService.summaryItems$$;

    if (form) {
      const data = summaryItems.value?.filter(e => !e?.['dateRange']).slice(0) || [];
      const value = formatDateRange(form);
      value?.['output'] && data.push(formatDateRange(form));
      summaryItems.next(data);
    } else {
      const data = summaryItems.value?.filter(e => !e?.['dateRange']).slice(0) || [];
      summaryItems.next(data);
    }
  }

}

function formatDateRange({ start, end }: FormDateRange): Record<string, any> {

  start && (start = new Date(start));
  end && (end = new Date(end));

  if (!end || end < start!) {
    return { output: '', dateRange: true };
  }

  function formatDate(date: Date) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getUTCDate() + 1;
    const month = months[date.getUTCMonth()];
    const suffix = day === 1 || day === 21 || day === 31 ? "st" : day === 2 || day === 22 ? "nd" : day === 3 || day === 23 ? "rd" : "th";
    return `${month} ${day}${suffix}`;
  };

  return {
    output: `${formatDate(start!)}-${formatDate(end)}`,
    dateRange: true
  };
};
