import { AfterViewInit, Directive, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent, filter } from 'rxjs';

import { ExperienceFinderStateService } from './experience-finder-state.service';

@Directive({
  selector: '[experienceFinderHover]'
})
export class ExperienceFinderHoverDirective implements AfterViewInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private element: ElementRef<HTMLElement>,
    private stateService: ExperienceFinderStateService
  ) { }

  ngAfterViewInit(): void {

    this.stateService.overlayState$.pipe(filter(Boolean)).subscribe(() => {

      const expFinder = [
        this.element.nativeElement.getElementsByClassName('experience-finder-ai').item(0) as HTMLElement
      ].filter(Boolean)[0];

      if (!expFinder) return;

      fromEvent(expFinder, 'mouseenter').subscribe(() => {
        this.document.body.classList.add('no-scroll')
      });
      fromEvent(expFinder, 'mouseleave').subscribe(() => {
        this.document.body.classList.remove('no-scroll')
      });
    });
  }

}
