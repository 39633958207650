import { ScreenView } from "../../shared";

export function getPlaceholderByScreen(screen: ScreenView): string {
  if (screen === ScreenView.travelCarousel) {
    return 'Describe your travel style here...';
  }
  if (screen === ScreenView.experienceCarousel) {
    return 'Describe your experience style here...';
  }
  if (screen === ScreenView.activitiesCarousel) {
    return 'Favorite activities?';
  }
  if (screen === ScreenView.locationCarousel) {
    return 'Choose or let us pick the best for you...';
  }
  if (screen === ScreenView.calendar) {
    return `If you don't know the dates, enter the # of days here...`;
  }
  if (screen === ScreenView.clientInfo) {
    return 'Enter the number of travelers and their age...';
  }
  if (screen === ScreenView.summary) {
    return 'You can delete tags above, add tags here, and then send it!';
  }
  return 'Type your request here...';
}
